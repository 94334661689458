import { useOktaAuth } from '@okta/okta-react'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { WalletProvider } from 'contexts/walletContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import WalletsTemplate from 'templates/wallet/walletTemplate'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const Wallet = () => {
  const navigate = useNavigate()
  const { authState, oktaAuth } = useOktaAuth()
  const { getStorageItem } = useLocalStorage()
  const {
    user,
    isLoadingProfile,
    isSuccessProfile,
    isBackoffice4c,
    isLogged,
    isPayments
  } = useProfile()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission = isBackoffice4c || isPayments

      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isBackoffice4c,
    navigate,
    user,
    authState,
    isLogged
  ])

  return (
    <ProfileProvider>
      <WalletProvider>
        <WalletsTemplate />
      </WalletProvider>
    </ProfileProvider>
  )
}

export default Wallet
