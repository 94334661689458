import { useOktaAuth } from '@okta/okta-react'
import { AccountEventsProvider } from 'contexts/accountEventsContext'
import { AccountWalletProvider } from 'contexts/accountWalletContext'
import { ConsumerProvider } from 'contexts/consumerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { TransactionsProvider } from 'contexts/transactionsProvider'
import { WalletTransactionProvider } from 'contexts/walletTransactionContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConsumerDetailsTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const ConsumerDetails = () => {
  const navigate = useNavigate()
  const { id: accountId } = useParams()
  const { getStorageItem } = useLocalStorage()
  const { authState, oktaAuth } = useOktaAuth()
  const {
    user,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isOperation3c,
    isAttendant3c,
    isAttendantB2M,
    isManageTransactions,
    isLoadingProfile,
    isSuccessProfile,
    isPayments,
    isSupervisorB2b
  } = useProfile()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission =
        isAttendant ||
        isFraudAndPrevention ||
        isBackoffice4c ||
        isOperation3c ||
        isAttendant3c ||
        isAttendantB2M ||
        isManageTransactions ||
        isPayments ||
        isSupervisorB2b

      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isOperation3c,
    isAttendant3c,
    isAttendantB2M,
    isManageTransactions,
    isSupervisorB2b,
    navigate,
    user,
    authState
  ])

  return (
    <ProfileProvider>
      <AccountEventsProvider accountId={Number(accountId)}>
        <ConsumerProvider>
          <AccountWalletProvider>
            <WalletTransactionProvider>
              <TransactionsProvider>
                <ConsumerDetailsTemplate />
              </TransactionsProvider>
            </WalletTransactionProvider>
          </AccountWalletProvider>
        </ConsumerProvider>
      </AccountEventsProvider>
    </ProfileProvider>
  )
}

export default ConsumerDetails
