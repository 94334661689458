import { Button, Flex, Icon, Text, VStack } from 'components/atoms'
import { Modal, TextArea } from 'components/ui'
import { ControlledInput, UncontrolledInput } from 'components/ui/Input'
import { useAccountWallet } from 'contexts/accountWalletContext'
import {
  CreditInTrustTypes,
  useWalletTransaction
} from 'contexts/walletTransactionContext'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { goToSection } from 'utils/windowUtils'

type ProvisionalCreditProps = {
  isOpen: boolean
  onClose: () => void
  transaction: WalletTransaction
}

export const ProvisionalCredit = ({
  isOpen,
  onClose,
  transaction
}: ProvisionalCreditProps) => {
  const [formattedValue, setFormattedValue] = useState('0')
  const { alert } = useAlert()
  const { refreshWalletList } = useAccountWallet()

  const isValidValue =
    Number(formattedValue) > Number(transaction.value) ||
    Number(formattedValue) === 0

  const [isValidOcorrenceCode, setIsValidOcorrenceCode] = useState(false)
  const [isValidReason, setIsValidReason] = useState(false)

  const isValidFields = isValidOcorrenceCode && isValidReason && !isValidValue
  const maxTextAreaLenght = 300

  const {
    addProvisionalCredit,
    isLoadingProvisionalCredit,
    isSuccessProvisionalCredit,
    getAllTransactions
  } = useWalletTransaction()

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreditInTrustTypes>()

  const onSubmit: SubmitHandler<CreditInTrustTypes> = async formData => {
    if (!formData || !transaction) {
      return alert({
        id: 'creditInTrustModal',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'warning'
      })
    }

    if (Number(formattedValue) > Number(transaction.value)) {
      return alert({
        status: 'warning',
        id: 'creditInTrust',
        title:
          'O valor do crédito não pode ser maior que o valor da própria transação.'
      })
    }

    addProvisionalCredit({
      value: formattedValue,
      uuid: transaction.transactionUUID,
      authorization_code: transaction.authorizationCode,
      occorence_code: formData.occorence_code,
      reason: formData.reason
    })
      .then(() => {
        refreshWalletList()
        getAllTransactions({ page: 0 })
        goToSection('consumerWallets-section')

        alert({
          id: 'provisionalCreditToast',
          title: 'Crédito provisório realizado com sucesso!',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)

        return alert({
          id: 'creditReversalModal',
          title: 'Ocorreu um erro, tente novamente!',
          status: 'warning'
        })
      })
  }

  useEffect(() => {
    if (isSuccessProvisionalCredit) {
      if (transaction) setFormattedValue(String(transaction.value))

      reset()
      onClose()
    }
  }, [isSuccessProvisionalCredit])

  useLayoutEffect(() => {
    if (transaction) setFormattedValue(String(transaction.value))
  }, [transaction])

  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          onClose()
          reset()
        }}
      />

      <SubmitButton
        title="Confirmar"
        isDisabled={!isValidFields}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingProvisionalCredit}
      />
    </Flex>
  )

  return (
    <Modal
      heading={'Reversão de crédito'}
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      decreaseMarginTop
    >
      <VStack spacing="xxs">
        <Text color="#221C46" fontSize="16px" fontWeight="500">
          {translate('commons.trustCreditTransaction')}
        </Text>

        <WarningMessage />

        <Flex w="100%" flexDir="column" gap="4px">
          <ControlledInput
            id="value"
            height="40px"
            title={'Valor do crédito provisório'}
            isInvalid={!!errors.value}
            isRequired
            placeholder="Valor"
            value={Mask.moneyInput(String(formattedValue))}
            onChange={event => {
              setFormattedValue(
                Mask.removeMoneyInput(event.currentTarget.value)
              )
            }}
            register={register}
          />

          {Number(formattedValue) > Number(transaction.value) && (
            <Text color="#B30000" fontWeight="600" fontSize="12px">
              O valor do estorno não pode ser maior que o valor da própria
              transação
            </Text>
          )}
        </Flex>

        <UncontrolledInput
          id="occorence_code"
          height="40px"
          title={translate('commons.occurrenceCode')}
          isInvalid={!!errors.occorence_code}
          isRequired
          requiredMessage="Código da ocorrência é obrigatório"
          placeholder="Informe o código da ocorrência"
          control={control}
          onChange={event => {
            const inputValue = event.currentTarget.value
            if (inputValue.length >= 1) setIsValidOcorrenceCode(true)
            else setIsValidOcorrenceCode(false)
          }}
        />

        <TextArea
          id="reason"
          maxLenght={maxTextAreaLenght}
          title={translate('commons.reason')}
          isInvalid={!!errors.reason}
          placeholder="Informe o motivo do crédito provisório"
          isRequired
          control={control}
          onChange={event => {
            const value = event.target.value

            if (
              Number(value.length) <= maxTextAreaLenght &&
              Number(value.length) >= 1
            ) {
              setIsValidReason(true)
            } else {
              setIsValidReason(false)
            }
          }}
        />
      </VStack>
    </Modal>
  )
}

const WarningMessage = () => (
  <Flex
    bg="#FBF2EC"
    h="100%"
    p="16px 12px"
    gap="12px"
    alignItems="center"
    mb="24px"
  >
    <Icon name="warning" color="#221C46" size="xs" />
    <Text fontSize="14px" color="#874810" fontWeight="600">
      Atenção ao preencher as informações. Criar crédito provisório é uma ação
      que não pode ser desfeita.
    </Text>
  </Flex>
)

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      color="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      bg="transparent"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        outline: '3px solid #1B51DC',
        boxShadow: 'none !important'
      }}
      onClick={onClick}
    >
      <Text fontSize="14px" fontWeight="700">
        Cancelar
      </Text>
    </Button>
  )
}

const SubmitButton = ({
  isLoading,
  isDisabled,
  onClick,
  title
}: {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  return (
    <Button
      h="48px"
      p="4px 8px"
      bg="#1B51DC"
      color="white"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      _hover={{ ...defaultStyles, border: 'none' }}
      _active={{
        ...defaultStyles,
        background: 'pluxee.cta.secondaryPressed',
        color: 'standard.white',
        borderColor: 'pluxee.cta.secondaryPressed',
        boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
      }}
      _focusVisible={{
        ...defaultStyles,
        boxShadow: 'none !important'
      }}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
    >
      <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
        {title}
      </Text>
    </Button>
  )
}
