import { useOktaAuth } from '@okta/okta-react'
import { Loading } from 'components/ui'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { HomeTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const Home = () => {
  const { user, isLoadingProfile, isSuccessProfile } = useProfile()
  const { authState, oktaAuth } = useOktaAuth()
  const { getStorageItem } = useLocalStorage()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      if (user && !authState?.isAuthenticated && !isDevSide()) {
        oktaAuth.signInWithRedirect()
      }
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [isLoadingProfile, isSuccessProfile, user, authState])

  if (isLoadingProfile) return <Loading />

  return (
    <ProfileProvider>
      <HomeTemplate />
    </ProfileProvider>
  )
}

export default Home
