import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Spinner,
  useDisclosure,
  VStack,
  Text,
  Stack,
  Divider
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { Modal, CloseButton, Checkbox } from 'components/ui'
import {
  AccountCardsProvider,
  useAccountCards
} from 'contexts/accountCardsContext'
import { translate } from 'internationalization'
import { Account } from 'models/Account'
import React, { useEffect, useLayoutEffect, useState } from 'react'

import { MultiBenefitCard } from './multiBenefitCard/multiBenefitCard'

type CardsModalModalProps = {
  isOpen: boolean
  onClose: () => void
  account: Account
}

export const CardsModal = ({
  isOpen,
  onClose,
  account
}: CardsModalModalProps) => {
  const modalFooter = <CloseButton onClick={onClose} />

  return (
    <AccountCardsProvider accountId={account.accountId}>
      <Modal
        heading={translate('commons.linkedCards')}
        footer={modalFooter}
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        decreaseMarginTop
        closeOnOverlayClick={false}
      >
        <CardsList />
      </Modal>
    </AccountCardsProvider>
  )
}

export const CardsList = () => {
  const {
    cards,
    isLoading,
    isError,
    isBlockSuccess,
    isUnblockSuccess,
    refetch: refreshWalletList
  } = useAccountCards()
  const isShowNoDataFound = isError || cards.length < 1

  useLayoutEffect(() => {
    if (isBlockSuccess) refreshWalletList()
  }, [isBlockSuccess])

  useLayoutEffect(() => {
    if (isUnblockSuccess) refreshWalletList()
  }, [isUnblockSuccess])

  return (
    <VStack spacing="xxs">
      <Flex justifyContent="flex-end" w="100%">
        <FilterCard />
      </Flex>
      <Grid
        gridTemplateColumns={isShowNoDataFound ? '' : 'auto auto'}
        gap="24px"
        width="100%"
      >
        {isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}

        {isShowNoDataFound && (
          <Center w="100%">
            <NoDataFound />
          </Center>
        )}

        {cards.map(card => (
          <GridItem key={card.cardId}>
            <MultiBenefitCard card={card} />
          </GridItem>
        ))}
      </Grid>
    </VStack>
  )
}

const FilterCard = () => {
  const { filter, setFilter } = useAccountCards()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isValidFilter = filter.status !== 'ALL'

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const formatStatus = (status: 'ACTIVE' | 'BLOCKED' | 'CANCELLED') => {
    const data = {
      ACTIVE: 'Ativos',
      BLOCKED: 'Bloqueados',
      CANCELLED: 'Cancelados'
    }

    return data[status]
  }

  return (
    <>
      <Flex alignItems="center" gap="8px">
        {isValidFilter && (
          <Button
            h="32px"
            p="4px 8px"
            color="#221C46"
            bg="#E3E2FA"
            border="3px solid"
            borderRadius="none"
            borderColor="transparent"
            _hover={{ ...defaultStyles, bg: '#878DFA', color: 'white' }}
            _active={{
              ...defaultStyles,
              background: 'pluxee.cta.secondaryPressed',
              color: 'standard.white',
              borderColor: 'pluxee.cta.secondaryPressed',
              boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
            }}
            _focusVisible={{
              ...defaultStyles,
              outline: '3px solid #1B51DC',
              boxShadow: 'none !important',
              color: '#221C46',
              bg: '#E3E2FA'
            }}
            gap="6px"
            onClick={() => setFilter({ ...filter, status: 'ALL' })}
          >
            <Text fontSize="14px" fontWeight="700">
              {formatStatus(
                filter.status as 'ACTIVE' | 'BLOCKED' | 'CANCELLED'
              )}
            </Text>

            <Icon name="close" size="md" color="#1B51DC" />
          </Button>
        )}

        <Button
          bg="transparent"
          w="93px"
          h="32px"
          onClick={onOpen}
          color="#1B51DC"
          border="3px solid"
          borderRadius="none"
          borderColor="#1B51DC"
          rightIcon={<Icon name="filter_list" size="xs" fontWeight="700" />}
          _hover={{ ...defaultStyles }}
          _active={{
            ...defaultStyles,
            background: 'pluxee.cta.secondaryPressed',
            color: 'standard.white',
            borderColor: 'pluxee.cta.secondaryPressed',
            boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
          }}
          _focusVisible={{
            ...defaultStyles,
            outline: '3px solid #1B51DC',
            boxShadow: 'none !important'
          }}
        >
          <Text fontSize="12px" fontWeight="700" letterSpacing="-0.6px">
            Filtrar
          </Text>
        </Button>
      </Flex>

      <FilterCards isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export type ValueEnum = 'Ver todos' | 'Ativos' | 'Bloqueados' | 'Cancelados'

export const FilterCards = ({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { filter, setFilter } = useAccountCards()
  const [value, setValue] = useState<string>(filter.status)

  const handleApply = () => {
    setFilter({ ...filter, status: value as any })
    onClose()
  }

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const modalFooter = (
    <Flex gap="16px">
      <Button
        bg="transparent"
        w="97px"
        h="48px"
        color="#1B51DC"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          outline: '3px solid #1B51DC',
          boxShadow: 'none !important'
        }}
        onClick={onClose}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
          Cancelar
        </Text>
      </Button>

      <Button
        w="82px"
        h="48px"
        bg="#1B51DC"
        color="white"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles, border: 'none' }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          boxShadow: 'none !important'
        }}
        onClick={handleApply}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.7px">
          Aplicar
        </Text>
      </Button>
    </Flex>
  )

  const formatValueText = (value: ValueEnum) => {
    const data = {
      'Ver todos': 'ALL',
      Ativos: 'ACTIVE',
      Bloqueados: 'BLOCKED',
      Cancelados: 'CANCELLED'
    }

    return data[value]
  }

  useEffect(() => {
    const checkboxGroupDiv =
      document.querySelectorAll<HTMLInputElement>('.pluxeeCheckbox')
    checkboxGroupDiv?.forEach(item => {
      const formattedInnerText = formatValueText(item.innerText as ValueEnum)
      const checkbox = item?.children[1] as HTMLInputElement

      if (formattedInnerText !== value) {
        checkbox.style.borderColor = '#908C99'
      } else {
        checkbox.style.borderColor = '#1B51DC'
        checkbox.removeAttribute('data-focus')
        checkbox.removeAttribute('data-focus-visible')
      }
    })
  }, [value])

  return (
    <Modal
      heading="Filtrar"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <Stack spacing="xxxs">
        <Checkbox
          checked={value === 'ALL'}
          title="Ver todos"
          onChange={() => setValue('ALL')}
        />

        <Divider />

        <Checkbox
          checked={value === 'ACTIVE'}
          title="Ativos"
          onChange={() => setValue('ACTIVE')}
        />

        <Checkbox
          checked={value === 'BLOCKED'}
          title="Bloqueados"
          onChange={() => setValue('BLOCKED')}
        />

        <Checkbox
          checked={value === 'CANCELLED'}
          title="Cancelados"
          onChange={() => setValue('CANCELLED')}
        />
      </Stack>
    </Modal>
  )
}
