import { Button, useDisclosure } from '@chakra-ui/react'
import {
  Center,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
  Image,
  Box,
  IconButton,
  Icon
} from 'components/atoms'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useConsumer } from 'contexts/consumerContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { formatCurrency } from 'internationalization'
import type { AccountWallet } from 'models/AccountWallet'
import type { Product } from 'models/Product'
import { PropsWithChildren, useState } from 'react'
import React from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'

import { WalletModal } from './walletModal'
import { AccountWalletStatus } from './walletStatus'

type WalletCardProps = {
  wallet: AccountWallet
  product: Product
}

export const WalletCard = ({ wallet, product }: WalletCardProps) => {
  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  return (
    <>
      <Wrapper walletId={wallet.walletId}>
        <LeftSide />
        <RightSide
          wallet={wallet}
          product={product}
          onOpenDetails={onOpenDetails}
        />
      </Wrapper>

      <WalletModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        wallet={wallet}
        product={product}
      />
    </>
  )
}

type WrapperProps = PropsWithChildren & { walletId: number }

const Wrapper = ({ children, walletId }: WrapperProps) => {
  const { selectedWallet } = useAccountWallet()
  const { isShowAllTransactions } = useConsumer()

  const isSelected =
    selectedWallet?.walletId === walletId && !isShowAllTransactions

  return (
    <Flex flexDir="column" alignItems="center" justifyContent="center">
      <Flex
        border={'5px solid'}
        borderRadius="4px"
        borderColor={isSelected ? '#1B51DC' : 'transparent'}
        boxShadow={isSelected ? '1px 1px 0px 0px #221C4633' : 'none'}
        _hover={{
          borderColor: '#1B51DC'
        }}
      >
        <Flex
          minW="315px"
          maxW="315px"
          h="183px"
          borderRadius="4px"
          border={'1px solid'}
          boxShadow="1px 1px 0px 0px #221C4633"
          borderColor={isSelected ? 'transparent' : 'standard.white'}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}

const LeftSide = () => (
  <Flex
    w="39px"
    h="100%"
    bg="#D7FCEA"
    backgroundImage="url('/img/pluxee/cardBg.png')"
    backgroundRepeat="no-repeat"
    backgroundSize="auto"
    backgroundPosition="right"
  />
)

type RightSideProps = Pick<WalletCardProps, 'wallet' | 'product'> & {
  onOpenDetails: () => void
}

const RightSide = ({ wallet, onOpenDetails }: RightSideProps) => {
  const { selectWallet, selectedWallet } = useAccountWallet()
  const { setWalletId } = useWalletTransaction()
  const { setIsShowAllTransactions, isShowAllTransactions } = useConsumer()

  const isSelected =
    selectedWallet?.walletId === wallet.walletId && !isShowAllTransactions

  const isHealthWallet = wallet.walletName === 'Saúde & Bem-estar'
  const capitalizedWalletName = isHealthWallet
    ? capitalizeSentence(wallet.walletName)
    : capitalizeSentence(limitString(wallet.walletName, 16))

  const isUnlimitedDailyTx = wallet.dailyLimitTx >= 999999

  const handleSelect = () => {
    if (!isSelected) {
      selectWallet(wallet)
      setWalletId(wallet.walletId)
      setIsShowAllTransactions(false)
      return
    }

    if (isSelected && !isShowAllTransactions) {
      setIsShowAllTransactions(true)
      return
    }
  }

  const getTextColor = ({
    remaining,
    total,
    isUnlimited
  }: {
    remaining: number
    total: number
    isUnlimited?: boolean
  }) => {
    const isEmpty = remaining === 0
    if (isEmpty) return '#B30000'

    const isFullyRemaining = remaining === total && !isUnlimited
    if (isFullyRemaining) return '#008A34'

    return '#463F5F'
  }

  const balanceIsSmallerThan0 = wallet.balance < 0

  return (
    <Flex
      bg="pluxee.primarys.deepBlue"
      cursor="pointer"
      onClick={handleSelect}
      flex="1"
      h="100%"
      p="12px"
      flexDir="column"
    >
      {/* Info Area */}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        mb="10px"
      >
        <Flex flex="5" gap="8px" alignItems="center">
          <Center
            w="32px"
            h="32px"
            borderRadius="4px"
            bg={renderWalletBg(capitalizedWalletName as WalletIcon)}
          >
            {renderWalletIcon(capitalizedWalletName as WalletIcon)}
          </Center>

          <Flex w="90%" flexDir="column" color="pluxee.text.primary" gap="1px">
            <Text
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
              letterSpacing="-0.8px"
            >
              {capitalizedWalletName}
            </Text>

            <AccountWalletStatus status={wallet.status} />
          </Flex>
        </Flex>

        <Flex flex="1">
          <PluxeeMenu title="Detalhes da carteira" onClick={onOpenDetails} />
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        w="100%"
        alignItems="flex-start"
        maxH="47px"
        color={balanceIsSmallerThan0 ? '#B30000' : '#1A202C'}
        mb="8px"
      >
        <Text fontSize="12px" fontWeight="700" letterSpacing="-0.6px">
          Saldo total
        </Text>
        <Text fontSize="24px" fontWeight="700" letterSpacing="-1.2px">
          {formatCurrency(wallet.balance)}
        </Text>
      </Flex>

      {/* Withdraw Info Area */}
      <Flex flexDir="column" bg="#F0EEF5" w="100%" padding="8px" gap="4px">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="pluxee.text.secondary"
          lineHeight="16.2px"
        >
          <Text fontWeight="500" fontSize="xxxs">
            {getFormattedDailyLimitLabel({
              value: 'Limite diário disponível:',
              isLimitString: String(wallet.dailyLimitValueRemaining).length >= 4
            })}
          </Text>
          <Text
            fontWeight="600"
            fontSize="xxxs"
            color={getTextColor({
              remaining: wallet.dailyLimitValueRemaining!,
              total: wallet.dailyLimitValue!
            })}
          >
            {wallet.dailyLimitValueRemaining &&
              formatToBrl(wallet.dailyLimitValueRemaining ?? 0)}
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="pluxee.text.secondary"
          lineHeight="16.2px"
        >
          <Text fontWeight="500" fontSize="xxxs" whiteSpace="nowrap">
            {getFormattedDailyLimitLabel({
              value: 'Limite de transações diárias:',
              isLimitString:
                (String(wallet.dailyLimitTxRemaining).length >= 3 &&
                  !isUnlimitedDailyTx) ||
                (String(wallet.dailyLimitTxRemaining).length >= 2 &&
                  !isUnlimitedDailyTx),
              totalCharToLimit:
                String(wallet.dailyLimitTxRemaining).length >= 3
                  ? 20
                  : String(wallet.dailyLimitTxRemaining).length >= 2
                  ? 25
                  : 0
            })}
          </Text>
          <Text
            fontWeight="600"
            fontSize="xxxs"
            whiteSpace="nowrap"
            color={getTextColor({
              remaining: wallet.dailyLimitTxRemaining!,
              total: wallet.dailyLimitTx!,
              isUnlimited: isUnlimitedDailyTx
            })}
          >
            {isUnlimitedDailyTx && 'Ilimitado'}
            {!isUnlimitedDailyTx &&
              `${wallet.dailyLimitTxRemaining} de ${wallet.dailyLimitTx}`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export type WalletIcon =
  | 'Alimentação'
  | 'Refeição'
  | 'Gift'
  | 'Mobilidade'
  | 'Educação'
  | 'Saúde & Bem-estar'
  | 'Home Office'
  | 'Educacao e Cultura'

export const renderWalletIcon = (walletName: WalletIcon) => {
  const data = {
    Alimentação: (
      <Image src="/icons/pluxee/alimentacao.svg" alt="Wallet Icon" />
    ),
    Refeição: <Image src="/icons/pluxee/refeicao.svg" alt="Wallet Icon" />,
    Gift: <Image src="/icons/pluxee/gift.svg" alt="Wallet Icon" />,
    Mobilidade: <Image src="/icons/pluxee/mobilidade.svg" alt="Wallet Icon" />,
    'Saúde & Bem-estar': (
      <Image src="/icons/pluxee/saudebemestar.svg" alt="Wallet Icon" />
    ),
    'Home Office': (
      <Image src="/icons/pluxee/homeoffice.svg" alt="Wallet Icon" />
    ),
    Educação: <Image src="/icons/pluxee/educacao.svg" alt="Wallet Icon" />,
    'Educacao e Cultura': (
      <Image src="/icons/pluxee/educacao.svg" alt="Wallet Icon" />
    ),
    Default: <Image src="/icons/pluxee/default.svg" alt="Wallet Icon" />
  }

  return data[walletName] ?? data['Default']
}

export const renderWalletBg = (walletName: WalletIcon) => {
  const data = {
    Alimentação: '#C7FCCA',
    Refeição: '#C7FCCA',
    Gift: '#FDEEC1',
    Mobilidade: '#FCC1BE',
    'Saúde & Bem-estar': '#FDEEC1',
    'Home Office': '#FDEEC1',
    Educação: '#FDEEC1',
    'Educacao e Cultura': '#FDEEC1',
    Default: '#DEF3FB'
  }

  return data[walletName] ?? data['Default']
}

export const WalletStatus = ({
  status,
  fontSize = '10px',
  height = '16px',
  isConsumerWallet = false
}: {
  status: string
  fontSize?: string
  height?: string
  isConsumerWallet?: boolean
}) => {
  const isActive = status === 'ACTIVE'

  return (
    <Flex
      bg="standard.white"
      w="fit-content"
      h={height}
      p="4px 8px"
      alignItems="center"
      justifyContent="center"
      gap="4px"
      border="1px solid"
      borderColor={isActive ? 'pluxee.border.shiped' : 'pluxee.border.error'}
    >
      <Box
        w="8px"
        h="8px"
        borderRadius="50%"
        bg={isActive ? 'pluxee.border.shiped' : 'pluxee.border.error'}
      />
      <Text fontWeight="500" fontSize={fontSize} lineHeight="16px">
        {isActive && isConsumerWallet && 'Ativa'}
        {!isActive && isConsumerWallet && 'Inativa'}

        {isActive && !isConsumerWallet && 'Habilitado'}
        {!isActive && !isConsumerWallet && 'Desabilitado'}
      </Text>
    </Flex>
  )
}

// temp
const PluxeeMenu = ({
  title,
  isDisabled = false,
  onClick
}: {
  title: string
  isDisabled?: boolean
  onClick: () => void
}) => {
  const [boxShadow, setBoxShadow] = useState('')

  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        as={IconButton}
        color="pluxee.text.link"
        borderRadius="none"
        aria-label="Menu Options"
        variant="unstyled"
        background="transparent"
        onClick={event => {
          setBoxShadow('4px 4px 0px 0px #A9A7B6')
          event.stopPropagation()
        }}
        _hover={{ color: 'pluxee.cta.secondaryPressed' }}
        _active={{
          color: 'pluxee.cta.secondaryPressed',
          border: '3px solid',
          borderColor: 'pluxee.text.link'
        }}
        _focusVisible={{
          border: '3px solid',
          borderColor: 'pluxee.text.link',
          color: 'pluxee.cta.secondaryPressed'
        }}
        icon={
          <Icon name="more_vert" variant="outlined" size="md" color="inherit" />
        }
      />

      <MenuList
        p="0"
        borderRadius="0"
        border="none"
        boxShadow={boxShadow}
        _hover={{
          background: 'transparent',
          boxShadow: 'none'
        }}
      >
        <MenuItem title={title} isDisabled={isDisabled} onClick={onClick} />
      </MenuList>
    </Menu>
  )
}

const MenuItem = ({
  title,
  isDisabled,
  onClick
}: {
  title: string
  isDisabled: boolean
  onClick: () => void
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.text.primary'
  }

  return (
    <ChakraMenuItem
      as={Button}
      borderRadius="none"
      onClick={onClick}
      isDisabled={isDisabled}
      h="48px"
      background="standard.white"
      border="1px solid"
      borderColor="pluxee.border.secondary"
      fontWeight="600"
      fontSize="xxs"
      color="pluxee.text.primary"
      boxShadow="4px 4px 0px 0px #A9A7B6"
      outline="none"
      gap="8px"
      paddingInlineStart="8px"
      paddingInlineEnd="30px"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        color: 'standard.white',
        background: 'pluxee.cta.secondaryPressed'
      }}
      _focusVisible={{ ...defaultStyles, border: '3px solid #1B51DC' }}
    >
      {title}
    </ChakraMenuItem>
  )
}

function getFormattedDailyLimitLabel({
  value,
  isLimitString,
  totalCharToLimit = 18
}: {
  value: string
  isLimitString: boolean
  totalCharToLimit?: number
}) {
  const defaultLabel = value
  const formattedDailyLimitLabel = isLimitString
    ? limitString(defaultLabel, totalCharToLimit)
    : defaultLabel

  return formattedDailyLimitLabel
}
