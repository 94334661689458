import { useAlert } from 'hooks/useAlert'
import { BlockCard } from 'models/request/BlockCard'
import { UnblockCard } from 'models/request/UnblockCard'
import { useMutation, useQuery } from 'react-query'
import { AccountService } from 'services/accountService'

export function useQueryGetEvents(accountId?: number) {
  return useQuery(['getEvents', accountId], () => getEvents(accountId))
}

export function useQueryGetEventsByFilter() {
  return useMutation(getEventsByFilter)
}

export function useQueryGetCards(accountId?: number) {
  return useQuery(['getCards', accountId], () => getCards(accountId))
}

export function useMutationBlockCard() {
  return useMutation(({ cardId, productId, reason }: BlockCard) =>
    blockCard({ cardId, productId, reason })
  )
}

export function useMutationUnblockCard() {
  return useMutation(({ cardId, productId, reason }: UnblockCard) =>
    unblockCard({ cardId, productId, reason })
  )
}

const getEvents = async (accountId?: number) => {
  if (!accountId) return

  const response = await AccountService.getEvents(accountId)
  return response.data
}

const getEventsByFilter = async ({
  dtStart,
  dtEnd,
  accountId
}: {
  dtStart?: string
  dtEnd?: string
  accountId?: string
}) => {
  if (!accountId) return

  const response = await AccountService.getEventsByFilter({
    dtStart,
    dtEnd,
    accountId
  })

  return response.data
}

const getCards = async (accountId?: number) => {
  if (!accountId) return

  const response = await AccountService.getCards(accountId)
  return response.data
}

const blockCard = async ({ cardId, productId, reason }: BlockCard) => {
  const { alert } = useAlert()

  await AccountService.blockCard({
    cardId,
    productId,
    reason
  })
    .then(data => {
      if (data.status === 200) {
        alert({
          id: 'successBlockCard',
          status: 'success',
          title: 'Ação de segurança realizada com sucesso'
        })
      }

      return data
    })
    .catch(err => {
      console.error(err)
      alert({
        id: 'errorBlockCard',
        status: 'error',
        title:
          err?.response?.data?.message ?? 'Ocorreu um erro, tente novamente!'
      })
    })
}

const unblockCard = async ({ cardId, productId, reason }: UnblockCard) => {
  const response = await AccountService.unblockCard({
    cardId,
    productId,
    reason
  }).then(data => {
    if (data.status === 200) {
      const { alert } = useAlert()

      alert({
        id: 'successOrderCode',
        status: 'success',
        title: 'Desbloqueio efetuado com sucesso'
      })
    }

    return data
  })

  return response.data
}
