import { Flex, Icon, Text } from 'components/atoms'
import { ButtonCalendar } from 'components/organisms'
import { useAuth } from 'contexts/authContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useToast } from 'hooks/useToast'
import { type WalletTransationTypes } from 'models/WalletTransaction'
import React, { useCallback, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { insertZero } from 'utils/csvUtils'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'

import { ExportTransactionButton } from '../details/transactions/button-export'

export const formatDateToFilter = (value: Date) => {
  const currentYear = value.getFullYear()
  const currentMonth = value.getMonth() + 1
  const currentDay = value.getDate()

  return `${insertZero(currentYear)}-${insertZero(currentMonth)}-${insertZero(
    currentDay
  )}`
}

type FilterListByPeriodProps = {
  type: 'transactions' | 'exits' | 'appetizer'
  exportType: 'transactions' | 'exits' | 'appetizer'
  excludeType?: WalletTransationTypes
}

export const FilterListByPeriod = ({
  type,
  exportType,
  excludeType
}: FilterListByPeriodProps) => {
  const [filteredPeriod, setFilteredPeriod] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const { userLevel } = useAuth()

  const isAttendant3c = userLevel === 'Atendimento-3C'
  const isAttendantB2M = userLevel === 'Atendimento B2M'

  const { getTransactiosByFilter, currentFilters } = useWalletTransaction()
  const { toast } = useToast()

  const onApply = useCallback((range?: DateRange) => {
    if (!range)
      return toast({
        status: 'warning',
        id: 'PeriodFilter',
        description: 'Selecione alguma data'
      })

    const dtStart = formatDateToFilter(range?.from ?? new Date())
    const dtEnd = formatDateToFilter(range?.to ?? new Date())

    setDateStart(dtStart)
    setDateEnd(dtEnd)

    setFilteredPeriod(
      `${formatDateToBrazilianFormat(
        range?.from ? range.from.toISOString() : ''
      )} - ${formatDateToBrazilianFormat(
        range?.to ? range.to.toISOString() : ''
      )}`
    )

    if (type === 'exits') {
      delete currentFilters.type
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        operation: '-',
        excludeType
      })

      return
    }

    if (type === 'transactions') {
      delete currentFilters.operation
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        type: 'PTX'
      })

      return
    }

    if (type === 'appetizer') {
      delete currentFilters.type
      getTransactiosByFilter({
        ...currentFilters,
        dtStart,
        dtEnd,
        operation: '%2B'
      })

      return
    }
  }, [])

  const handleRemoveFilter = () => {
    delete currentFilters.dtStart
    delete currentFilters.dtEnd

    getTransactiosByFilter({ ...currentFilters })
    setFilteredPeriod('')
  }

  const renderFilteredLabel = useCallback(() => {
    if (!filteredPeriod) return null

    return (
      <Flex
        bg="#283897"
        borderRadius="8px"
        p="5px 10px"
        color="white"
        gap="8px"
        justifyContent="center"
        alignItems="center"
      >
        <Flex onClick={handleRemoveFilter} cursor="pointer">
          <Icon name="close" size="xs" mb="2px" />
        </Flex>
        <Text fontSize="xxs" fontWeight="semibold">
          {filteredPeriod}
        </Text>
        <Icon name="calendar_month" size="xs" mb="3px" />
      </Flex>
    )
  }, [filteredPeriod])

  const renderExportTransactionButton = useCallback(
    (dtStart: string, dtEnd: string) => {
      if (isAttendant3c || isAttendantB2M) return <></>

      if (exportType === 'transactions')
        return (
          <ExportTransactionButton
            transactionType="PTX"
            type="transacoes"
            dtStart={dtStart}
            dtEnd={dtEnd}
            disableExportButton={filteredPeriod ? false : true}
          />
        )
      if (exportType === 'exits')
        return (
          <ExportTransactionButton
            operationType="-"
            type="saidas"
            dtStart={dtStart}
            dtEnd={dtEnd}
            disableExportButton={filteredPeriod ? false : true}
          />
        )

      if (exportType === 'appetizer')
        return (
          <ExportTransactionButton
            operationType="%2B"
            type="entradas"
            dtStart={dtStart}
            dtEnd={dtEnd}
            disableExportButton={filteredPeriod ? false : true}
          />
        )
    },
    [filteredPeriod, dateStart, dateEnd]
  )

  return (
    <Flex gap="16px" flexWrap="wrap">
      {renderFilteredLabel()}

      {!filteredPeriod && (
        <ButtonCalendar onApply={onApply} resetRange={!filteredPeriod} />
      )}

      {renderExportTransactionButton(dateStart, dateEnd)}
    </Flex>
  )
}
