import {
  useQueryGetConsumerDetails,
  useQueryGetWalletsByAccount
} from 'hooks/queries/accountWalletQueries'
import { Account } from 'models/Account'
import { AccountWallet } from 'models/AccountWallet'
import { Product } from 'models/Product'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

export type ContextType = {
  accountId?: number
  setAccountId: (value: number) => void
  account: Account | null
  wallets: AccountWallet[]
  walletProduct: Product | null
  selectedWallet: AccountWallet | null
  isLoadingConsumerData: boolean
  isErrorConsumerData: boolean
  isLoading: boolean
  isRefetching: boolean
  setAccount: (account: Account | null) => void
  selectWallet: (wallet: AccountWallet) => void
  refreshWalletList: () => void
}

export const Context = createContext({} as ContextType)

type AccountWalletProviderProps = {
  children: ReactNode
}

export const AccountWalletProvider = ({
  children
}: AccountWalletProviderProps) => {
  const [wallets, setWallets] = useState<AccountWallet[]>([])
  const [walletProduct, setWalletProduct] = useState<Product | null>(null)

  const [accountId, setAccountId] = useState<number>()
  const [account, setAccount] = useState<Account | null>(null)

  const [selectedWallet, setSelectedWallet] = useState<AccountWallet | null>(
    null
  )

  const {
    data: consumerData,
    isLoading: isLoadingConsumerData,
    isError: isErrorConsumerData
  } = useQueryGetConsumerDetails(accountId)

  const { data, isLoading, refetch, isRefetching } =
    useQueryGetWalletsByAccount(accountId)

  const selectWallet = (wallet: AccountWallet) => setSelectedWallet(wallet)

  useEffect(() => {
    if (consumerData) setAccount(consumerData)
  }, [consumerData, isErrorConsumerData])

  useEffect(() => {
    if (accountId && data) {
      setWallets(data?.accountWallets)
      setWalletProduct(data?.product)
    }
  }, [accountId, data])

  return (
    <Context.Provider
      value={{
        account,
        accountId,
        setAccountId,
        wallets,
        walletProduct,
        selectedWallet,
        isLoading,
        isRefetching,
        selectWallet,
        setAccount,
        isErrorConsumerData,
        isLoadingConsumerData,
        refreshWalletList: refetch
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAccountWallet = () => {
  const context = useContext(Context)

  return context
}
