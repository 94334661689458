import { Flex, Image, Text } from 'components/atoms'
import { Loading } from 'components/ui'
import { Container, HomeCard, Layout } from 'components/ui'
import { ProfileContextType, useProfile } from 'contexts/profileContext'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const HomePluxee = () => {
  const navigate = useNavigate()
  const {
    user,
    isPayments,
    isProfileAdmin,
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isBackoffice4c,
    isOperation3c,
    isFraudAndPrevention,
    isManageTransactions,
    isSupervisorB2b
  } = useProfile()

  if (!user) return <Loading />

  if (isProfileAdmin) navigate('/user')

  return (
    <Layout>
      <Container>
        <Main
          isBackoffice4c={isBackoffice4c}
          isAttendantB2M={isAttendantB2M}
          isAttendant3c={isAttendant3c}
          isAttendant={isAttendant}
          isProfileAdmin={isProfileAdmin}
          isOperation3c={isOperation3c}
          isFraudAndPrevention={isFraudAndPrevention}
          isManageTransactions={isManageTransactions}
          isPayments={isPayments}
          isSupervisorB2b={isSupervisorB2b}
        />
      </Container>
    </Layout>
  )
}

export default HomePluxee

type MainProps = Pick<
  ProfileContextType,
  | 'isAttendant'
  | 'isAttendant3c'
  | 'isAttendantB2M'
  | 'isBackoffice4c'
  | 'isFraudAndPrevention'
  | 'isManageTransactions'
  | 'isOperation3c'
  | 'isProfileAdmin'
  | 'isPayments'
  | 'isSupervisorB2b'
>

const Main = ({
  isAttendant,
  isAttendant3c,
  isAttendantB2M,
  isBackoffice4c,
  isFraudAndPrevention,
  isManageTransactions,
  isOperation3c,
  isProfileAdmin,
  isPayments,
  isSupervisorB2b
}: MainProps) => (
  <Flex flexDir="column" bg="standard.white" w="100%" p="40px" gap="32px">
    <Flex flexDir="column" gap="16px" color="pluxee.text.primary">
      <Text fontWeight="700" fontSize="md">
        Menu
      </Text>
      <Text fontWeight="500" fontSize="xxs">
        Selecione abaixo o que deseja fazer a seguir.
      </Text>
    </Flex>

    <Flex gap="32px" flexWrap="wrap">
      {isAttendant && <AttendantCards />}
      {isBackoffice4c && <Backoffice4cCards />}
      {isFraudAndPrevention && <AttendantCards />}
      {isProfileAdmin && <UserAdminCards />}

      {isPayments && <PaymentsCards />}

      {isOperation3c && <Operations3cCards />}
      {isAttendant3c && <Attendant3CCards />}
      {isManageTransactions && <TransactionOperationsCards />}

      {isAttendantB2M && <Attendant3CCards />}

      {isSupervisorB2b && <SupervisorB2BCards />}
    </Flex>
  </Flex>
)

const AttendantCards = () => (
  <HomeCard
    title="Consultar"
    icon={<Image src="/img/pluxee/searchIcon.png" />}
    items={[
      { label: 'Colaboradores', path: 'consumer' },
      { label: 'Clientes', path: 'customer' },
      { label: 'Agendamentos', path: 'schedules' }
    ]}
  />
)

const PaymentsCards = () => (
  <>
    <HomeCard
      title="Consultar"
      icon={<Image src="/img/pluxee/searchIcon.png" />}
      items={[
        { label: 'Colaboradores', path: 'consumer' },
        { label: 'Clientes', path: 'customer' },
        { label: 'Agendamentos', path: 'schedules' },
        { label: 'Transações', path: 'transactions' }
      ]}
    />

    <HomeCard
      title="Carteiras"
      icon={<Image src="/img/pluxee/walletIcon.png" />}
      items={[
        {
          label: 'Gerenciamento global',
          path: 'wallet',
          tooltipLabel:
            'As ações realizadas nesta área afetam todos os clientess Pluxee.'
        }
      ]}
    />
  </>
)

const Backoffice4cCards = () => (
  <>
    <HomeCard
      title="Consultar"
      icon={<Image src="/img/pluxee/searchIcon.png" />}
      items={[
        { label: 'Colaboradores', path: 'consumer' },
        { label: 'Clientes', path: 'customer' },
        { label: 'Agendamentos', path: 'schedules' },
        { label: 'Transações', path: 'transactions' }
      ]}
    />

    <HomeCard
      title="Carteiras"
      icon={<Image src="/img/pluxee/walletIcon.png" />}
      items={[
        {
          label: 'Gerenciamento global',
          path: 'wallet',
          tooltipLabel:
            'As ações realizadas nesta área afetam todos os clientess Pluxee.'
        },
        { label: 'Configurações por cliente', path: '/customized-clients' }
      ]}
    />
  </>
)

const SupervisorB2BCards = () => (
  <HomeCard
    title="Consultar"
    icon={<Image src="/img/pluxee/searchIcon.png" />}
    items={[
      { label: 'Colaboradores', path: 'consumer' },
      { label: 'Clientes', path: 'customer' },
      { label: 'Agendamentos', path: 'schedules' }
    ]}
  />
)

const UserAdminCards = () => (
  <>
    <HomeCard
      title="Perfis de usuários"
      icon={<Image src="/img/pluxee/usersIcon.png" />}
      items={[{ label: 'Visualizar usuários', path: 'user' }]}
    />
  </>
)

// 3C

const Attendant3CCards = () => (
  <HomeCard
    title="Consultas"
    icon={<Image src="/img/pluxee/searchIcon.png" />}
    items={[
      { label: 'Colaboradores', path: 'consumer' },
      { label: 'Agendamentos', path: 'schedules' }
    ]}
  />
)

const Operations3cCards = () => (
  <>
    <HomeCard
      title="Consultas"
      icon={<Image src="/img/pluxee/searchIcon.png" />}
      items={[
        { label: 'Colaboradores', path: 'consumer' },
        { label: 'Agendamentos', path: 'schedules' }
      ]}
    />
  </>
)

const TransactionOperationsCards = () => (
  <>
    <HomeCard
      title="Consultas"
      icon={<Image src="/img/pluxee/searchIcon.png" />}
      items={[{ label: 'Colaboradores', path: 'consumer' }]}
    />
  </>
)
