import {
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
  Image
} from 'components/atoms'
import { CloseButton, Modal } from 'components/ui'
import { AccountWallet } from 'models/AccountWallet'
import { Product } from 'models/Product'
import React from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { capitalizeSentence } from 'utils/stringUtils'

import { StatusInfo } from './statusInfo'
import { AccountWalletStatus } from './walletStatus'

type WalletModalProps = {
  isOpen: boolean
  onClose: () => void
  wallet: AccountWallet
  product: Product
}

export const WalletModal = ({
  isOpen,
  onClose,
  wallet,
  product
}: WalletModalProps) => {
  const isUnlimitedTransaction = wallet.dailyLimitTx === 999999
  const isUnlimitedValueTransaction = wallet.dailyLimitValue === 999999

  const isUnlimitedWithdraw = wallet.dailyLimitWithdrawTx === 999999
  const isUnlimitedValueWithdraw = wallet.dailyLimitWithdrawValue === 999999

  const isMonthlyUnlimitedWithdraw = wallet.monthlyLimitWithdrawTx === 999999
  const isMonthlyUnlimitedValueWithdraw =
    wallet.monthlyLimitWithdrawValue === 999999

  const isEmptyLimitAndValue =
    wallet.dailyLimitTxRemaining === 0 && wallet.dailyLimitValueRemaining === 0

  const isEmptyLimitAndValueWithdraw =
    wallet.dailyLimitWithdrawValueRemaining === 0 &&
    wallet.dailyLimitWithdrawTxRemaining === 0

  const isEmptyLimitAndValueWithdrawMonthly =
    wallet.monthlyLimitWithdrawValueRemaining === 0 &&
    wallet.monthlyLimitWithdrawTxRemaining === 0

  const modalFooter = <CloseButton onClick={onClose} />

  return (
    <Modal
      heading="Detalhes da Carteira"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      decreaseMarginTop
    >
      <VStack spacing="xxs" align="start">
        <HStack justify="space-between" w="100%">
          <Text fontWeight="700" color="pluxee.text.link" fontSize="md">
            {capitalizeSentence(wallet.walletName)}
          </Text>
        </HStack>

        <Divider />

        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Flex flexDir="column" gap="6px" alignItems="flex-start">
            <Flex gap="8px" alignItems="center">
              <Text fontWeight="700" fontSize="xs" color="pluxee.text.primary">
                Status:
              </Text>

              <AccountWalletStatus
                status={wallet.status}
                fontSize="14px"
                padding="4px 8px"
                hideTooltip
              />
            </Flex>
          </Flex>

          <StatusInfo
            walletStatus={wallet.walletStatus}
            customerWalletStatus={wallet.customerWalletStatus}
            isAccountWalletPaused={wallet.isAccountWalletPaused}
          />
        </Flex>

        <Divider />

        <Flex w="100%" gap="24px">
          <Flex flex="1" flexDir="column" gap="24px">
            <Flex
              gap="8px"
              flexDir="column"
              fontWeight="700"
              color="pluxee.text.primary"
              fontSize="xs"
            >
              <Text>Detalhes</Text>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Wallet aberta:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {wallet.open_wallet ? 'Sim' : 'Não'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Prioridade:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {wallet.walletPriority}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Produto:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {product?.productId} - {product?.name}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Permite transferência?
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {wallet.allow_transfer ? 'Sim' : 'Não'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Permite Saque?
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {wallet.allow_withdraw ? 'Sim' : 'Não'}
                </Text>
              </Flex>
            </Flex>

            <Divider />
          </Flex>
        </Flex>

        <Flex w="100%" gap="16px" flexDir="column">
          <Flex flex="1" flexDir="column" gap="16px">
            <Text
              color="pluxee.text.primary"
              fontWeight="700"
              lineHeight="24px"
              fontSize="xs"
            >
              Limites
            </Text>
          </Flex>

          <ContextBox
            title="Transações diárias"
            icon={<Image src="/icons/pluxee/payments.svg" alt="Payment Icon" />}
            isError={isEmptyLimitAndValue}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flexDir="column" align="start">
                <Text color="#221C46" fontSize="14px" fontWeight="700">
                  Saldo utilizado:
                </Text>

                <Flex alignItems="center" gap="4px">
                  <Text
                    fontSize="20px"
                    fontWeight="700"
                    color={isEmptyLimitAndValue ? '#C12D2D' : '#1B51DC'}
                  >
                    {wallet.dailyLimitValueUsed
                      ? formatToBrl(wallet.dailyLimitValueUsed)
                      : formatToBrl(0)}
                  </Text>
                </Flex>
              </Flex>

              {!isUnlimitedTransaction && (
                <Flex flexDir="column" align="end">
                  <Text color="#221C46" fontSize="14px" fontWeight="700">
                    Limite disponível:
                  </Text>
                  <Text
                    fontSize="20px"
                    fontWeight="700"
                    color={isEmptyLimitAndValue ? '#221C46' : '#1B51DC'}
                  >
                    {wallet.dailyLimitValueRemaining
                      ? formatToBrl(wallet.dailyLimitValueRemaining)
                      : formatToBrl(0)}
                  </Text>
                </Flex>
              )}
            </Flex>

            <Flex alignItems="center" justifyContent="space-between">
              <Text color="#221C46" fontSize="14px" fontWeight="700">
                Limite diário total:
              </Text>

              <Text color="#221C46" fontSize="14px" fontWeight="500">
                {isUnlimitedValueTransaction && 'Ilimitado'}

                {!isUnlimitedValueTransaction && wallet.dailyLimitValue
                  ? formatToBrl(wallet.dailyLimitValue)
                  : ''}
              </Text>
            </Flex>

            <Divider />

            <Flex alignItems="center" justifyContent="space-between">
              <Text color="#221C46" fontSize="14px" fontWeight="700">
                Transações restantes:
              </Text>

              <Flex gap="4px" alignItems="center">
                <Text
                  color={isEmptyLimitAndValue ? '#BD2120' : '#221C46'}
                  fontSize="14px"
                  fontWeight="500"
                >
                  {isUnlimitedTransaction && 'Ilimitado'}
                  {!isUnlimitedTransaction && (
                    <span>
                      {wallet.dailyLimitTxRemaining} de {wallet.dailyLimitTx}{' '}
                      transações
                    </span>
                  )}
                </Text>
              </Flex>
            </Flex>
          </ContextBox>

          {wallet.allow_withdraw && (
            <>
              <ContextBox
                icon={<Image src="/icons/pluxee/money.svg" alt="Money Icon" />}
                title={'Saques diários'}
                isError={isEmptyLimitAndValueWithdraw}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex flexDir="column" align="start">
                    <Text color="#221C46" fontSize="14px" fontWeight="700">
                      Saldo utilizado:
                    </Text>

                    <Flex alignItems="center" gap="4px">
                      <Text
                        fontSize="20px"
                        fontWeight="700"
                        color={
                          isEmptyLimitAndValueWithdraw ? '#C12D2D' : '#1B51DC'
                        }
                      >
                        {wallet.dailyLimitWithdrawValueUsed
                          ? formatToBrl(wallet.dailyLimitWithdrawValueUsed)
                          : formatToBrl(0)}
                      </Text>
                    </Flex>
                  </Flex>

                  {!isUnlimitedWithdraw && (
                    <Flex flexDir="column" align="end">
                      <Text color="#221C46" fontSize="14px" fontWeight="700">
                        Limite disponível:
                      </Text>
                      <Text
                        fontSize="20px"
                        fontWeight="700"
                        color={
                          isEmptyLimitAndValueWithdraw ? '#221C46' : '#1B51DC'
                        }
                      >
                        {isUnlimitedWithdraw && 'Ilimitado'}

                        {!isUnlimitedWithdraw &&
                        wallet.dailyLimitWithdrawValueRemaining
                          ? formatToBrl(wallet.dailyLimitWithdrawValueRemaining)
                          : formatToBrl(0)}
                      </Text>
                    </Flex>
                  )}
                </Flex>

                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="#221C46" fontSize="14px" fontWeight="700">
                    Limite diário total:
                  </Text>

                  <Text color="#221C46" fontSize="14px" fontWeight="500">
                    {isUnlimitedValueWithdraw && 'Ilimitado'}

                    {!isUnlimitedValueWithdraw && wallet.dailyLimitWithdrawValue
                      ? formatToBrl(wallet.dailyLimitWithdrawValue)
                      : ''}
                  </Text>
                </Flex>

                <Divider />

                <Flex flexDir="column" w="100%" gap="4px">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text color="#221C46" fontSize="14px" fontWeight="700">
                      Saques diários restantes:
                    </Text>

                    <Flex gap="4px" alignItems="center">
                      <Text
                        color={
                          isEmptyLimitAndValueWithdraw ? '#BD2120' : '#221C46'
                        }
                        fontSize="14px"
                        fontWeight="500"
                      >
                        {isUnlimitedWithdraw && 'Ilimitado'}
                        {!isUnlimitedWithdraw && (
                          <span>
                            {wallet.dailyLimitWithdrawTxRemaining} de{' '}
                            {wallet.dailyLimitWithdrawTx} saques
                          </span>
                        )}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex alignItems="center" justifyContent="space-between">
                    <Text color="#221C46" fontSize="14px" fontWeight="700">
                      Taxa de saque:
                    </Text>

                    <Flex gap="4px" alignItems="center">
                      <Text color="#221C46" fontSize="14px" fontWeight="500">
                        {wallet.withdrawFee
                          ? formatToBrl(wallet.withdrawFee)
                          : formatToBrl(0)}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </ContextBox>

              <ContextBox
                icon={<Image src="/icons/pluxee/money.svg" alt="Money Icon" />}
                title={'Saques mensais'}
                isError={isEmptyLimitAndValueWithdrawMonthly}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex flexDir="column" align="start">
                    <Text color="#221C46" fontSize="14px" fontWeight="700">
                      Saldo utilizado:
                    </Text>

                    <Flex alignItems="center" gap="4px">
                      <Text
                        fontSize="20px"
                        fontWeight="700"
                        color={
                          isEmptyLimitAndValueWithdrawMonthly
                            ? '#C12D2D'
                            : '#1B51DC'
                        }
                      >
                        {wallet.monthlyLimitWithdrawValueUsed
                          ? formatToBrl(wallet.monthlyLimitWithdrawValueUsed)
                          : formatToBrl(0)}
                      </Text>
                    </Flex>
                  </Flex>

                  {!isMonthlyUnlimitedWithdraw && (
                    <Flex flexDir="column" align="end">
                      <Text color="#221C46" fontSize="14px" fontWeight="700">
                        Limite disponível:
                      </Text>
                      <Text
                        fontSize="20px"
                        fontWeight="700"
                        color={
                          isEmptyLimitAndValueWithdrawMonthly
                            ? '#221C46'
                            : '#1B51DC'
                        }
                      >
                        {isMonthlyUnlimitedWithdraw && 'Ilimitado'}

                        {!isMonthlyUnlimitedWithdraw &&
                        wallet.monthlyLimitWithdrawValueRemaining
                          ? formatToBrl(
                              wallet.monthlyLimitWithdrawValueRemaining
                            )
                          : formatToBrl(0)}
                      </Text>
                    </Flex>
                  )}
                </Flex>

                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="#221C46" fontSize="14px" fontWeight="700">
                    Limite mensal total:
                  </Text>

                  <Text color="#221C46" fontSize="14px" fontWeight="500">
                    {isMonthlyUnlimitedValueWithdraw && 'Ilimitado'}

                    {!isMonthlyUnlimitedValueWithdraw &&
                    wallet.monthlyLimitWithdrawValue
                      ? formatToBrl(wallet.monthlyLimitWithdrawValue)
                      : formatToBrl(0)}
                  </Text>
                </Flex>

                <Divider />

                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="#221C46" fontSize="14px" fontWeight="700">
                    Saques mensais restantes:
                  </Text>

                  <Flex gap="4px" alignItems="center">
                    <Text
                      color={
                        isEmptyLimitAndValueWithdrawMonthly
                          ? '#BD2120'
                          : '#221C46'
                      }
                      fontSize="14px"
                      fontWeight="500"
                    >
                      {isMonthlyUnlimitedWithdraw && 'Ilimitado'}
                      {!isMonthlyUnlimitedWithdraw && (
                        <span>
                          {wallet.monthlyLimitWithdrawTxRemaining} de{' '}
                          {wallet.monthlyLimitWithdrawTx} saques
                        </span>
                      )}
                    </Text>
                  </Flex>
                </Flex>
              </ContextBox>
            </>
          )}
        </Flex>
      </VStack>
    </Modal>
  )
}

const ContextBox = ({
  children,
  icon,
  title,
  isError = false
}: {
  children: React.ReactNode
  icon: React.ReactNode
  title: string
  isError?: boolean
}) => (
  <Flex
    flexDir="column"
    p="16px"
    gap="16px"
    bg={isError ? '#FCF1EE' : '#FAF8FF'}
  >
    <Flex alignItems="center" gap="8px">
      <Center
        w="40px"
        h="40px"
        borderRadius="8px"
        bg={isError ? '#FBDDDA' : '#C7FCCA'}
      >
        {icon}
      </Center>

      <Text color="#221C46" fontWeight="700" fontSize="xs">
        {title}
      </Text>
    </Flex>

    {children}
  </Flex>
)
