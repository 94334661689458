import { Center, MenuButton as ChakraMenuButton, Image } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { PluxeeMenu } from 'components/ui'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import React from 'react'

export const AppetizerOptions = ({
  data,
  setWalletId,
  onOpenDetails,
  onOpenCreditReversal,
  isShowCreditReversal,
  setSelectedTransaction
}: {
  data: WalletTransaction
  setWalletId?: () => void
  isShowCreditReversal: boolean
  setSelectedTransaction: (data: WalletTransaction) => void
  onOpenDetails: () => void
  onOpenCreditReversal: () => void
}) => {
  return (
    <PluxeeMenu
      menuButton={<CustomMenuButton />}
      items={[
        {
          title: translate('commons.seeDetails'),
          onClick: () => {
            setWalletId && setWalletId()
            setSelectedTransaction(data)
            onOpenDetails()
          },
          isDisabled: false,
          icon: <Icon name="search" />
        },
        {
          title: translate('commons.creditReversal'),
          onClick: () => {
            setWalletId && setWalletId()
            setSelectedTransaction(data)
            onOpenCreditReversal()
          },
          isDisabled: !isShowCreditReversal,
          icon: (
            <Image
              w="24px"
              h="24px"
              src="/img/pluxee/provisional.svg"
              alt="Icon"
            />
          )
        }
      ]}
    />
  )
}

const CustomMenuButton = () => (
  <ChakraMenuButton
    w="40px"
    h="40px"
    borderRadius="0"
    background="transparent"
    color="#1B51DC"
    _active={{
      border: '2px solid #1B51DC'
    }}
    _focusVisible={{
      border: '3px solid #1B51DC',
      color: '#0F266D'
    }}
    _hover={{
      background: 'transparent',
      color: '#0F266D'
    }}
  >
    <Center>
      <Icon name="more_horiz" color="inherit" />
    </Center>
  </ChakraMenuButton>
)
