import {
  useQueryGetAccountDetailsById,
  useQueryGetTransactionByUUID,
  useQueryGetWalletDetailsById
} from 'hooks/queries/transactionsQueries'
import { Account } from 'models/Account'
import { AccountWallet } from 'models/AccountWallet'
import { WalletTransaction } from 'models/WalletTransaction'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { objectIsEmpty } from 'utils/objectUtils'

import { useWalletTransaction } from './walletTransactionContext'

export type ContextType = {
  transaction?: WalletTransaction
  account: Account

  wallet: {
    accountWallets: AccountWallet[]
  }

  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  isEmptyTransaction: boolean

  setTransactionUUID: (value: string) => void
}

export const Context = createContext({} as ContextType)

type TransactionsProviderProps = {
  children: ReactNode
}

export const TransactionsProvider = ({
  children
}: TransactionsProviderProps) => {
  const [transaction, setTransaction] = useState<WalletTransaction>()
  const [transactionUUID, setTransactionUUID] = useState('')
  const [accountId, setAccountId] = useState<string>()
  const [walletId, setWalletId] = useState<string>()
  const isEmptyTransaction = objectIsEmpty(transaction)

  const { isSuccessReverseCredit } = useWalletTransaction()

  const queryTransaction = useQueryGetTransactionByUUID(transactionUUID)
  const {
    isLoading,
    isError,
    isSuccess,
    data: transactionData
  } = queryTransaction

  const { data: accountData, isError: isErrorAccount } =
    useQueryGetAccountDetailsById(accountId)

  const {
    data: walletData,
    isError: isErrorWallet,
    refetch: updateWalletData
  } = useQueryGetWalletDetailsById({
    accountId,
    walletId
  })

  useEffect(() => {
    if (transactionUUID && transactionData) {
      setTransaction(transactionData)
      setAccountId(transactionData.accountId)
      setWalletId(transactionData.walletId)
    }
  }, [transactionUUID, transactionData, accountId])

  useEffect(() => {
    if (isError || isErrorAccount || isErrorWallet) {
      setTransaction(undefined)
      setAccountId(undefined)
      setWalletId(undefined)
    }
  }, [isError, isErrorAccount, isErrorWallet])

  useEffect(() => {
    if (isSuccessReverseCredit) updateWalletData()
  }, [isSuccessReverseCredit])

  return (
    <Context.Provider
      value={{
        transaction,
        account: accountData,
        wallet: walletData,
        setTransactionUUID,
        isLoading,
        isSuccess,
        isError,
        isEmptyTransaction
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useTransactions = () => useContext(Context)
