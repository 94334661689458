export const commons = {
  enter: `Entrar`,
  back: `Voltar`,
  save: `Salvar`,
  cancel: `Cancelar`,
  delete: `Excluir`,
  update: `Atualizar`,
  continue: `Continuar`,
  initialDate: `Data início`,
  finalDate: `Data fim`,
  register: `Cadastrar`,
  search: `Pesquisar`,
  add: `Adicionar`,
  close: `Fechar`,
  create: `Criar`,
  edit: `Editar`,
  filter: `Filtro`,
  yes: `Sim`,
  no: `Não`,
  ok: `OK`,
  download: `Download`,
  company: `Empresa`,
  issuingCompany: `Empresa emissora:`,
  clientCode: `Código do cliente:`,
  clientCodeMinimal: `Cód do cliente`,
  wallet: 'Carteira',
  walletActivate: `Ativar wallet`,
  walletInactivate: `Inativar wallet`,
  walletExclude: `Excluir Wallet`,
  openWallet: `Wallet aberta`,
  transfers: `Transferências`,
  walletForAttendance: `Carteira`,
  name: `Nome`,
  login: `Login`,
  profile: `Perfil`,
  editInfo: `Editar informações`,
  active: `Ativo`,
  inactive: `Inativo`,
  occurrenceCode: `Código da ocorrência`,
  table: {
    results: `{{total}} resultados`,
    dataNotFound: `Sem dados a serem exibidos`,
    dataNotFoundDesc: `Informações cadastradas aparecerão aqui`
  },
  account: {
    account: 'Conta',
    ALL: 'Todas as contas',
    ACTIVE: 'Contas Ativas',
    INACTIVE: 'Contas Inativas',
    BLOCKED: 'Contas Bloqueadas',
    CLOSED: 'Contas Fechadas',
    linkedAccounts: 'Contas vinculadas',
    accountData: `Dados da conta`,
    history: `Histórico da transação`,
    historyDescription: `Abaixo você pode conferir o fluxo de validação que a transação percorreu para ser aprovada ou negada.`
  },
  users: `Usuários`,
  user: {
    create: `Criar usuário`,
    createInfo: `Preencha as informações do novo usuário:`,
    edit: `Editar usuário`,
    editInfo: `Edite as informações do usuário:`,
    exclude: `Excluir usuário`,
    username: `Nome do usuário`,
    login: `Login`,
    email: `E-mail`,
    profile: `Perfil`,
    createSuccess: `Usuário criado com sucesso`,
    removeSuccess: `Usuário excluído com sucesso`,
    updateSuccess: `Usuário atualizado com sucesso`,
    errorCreateUser: `Não foi possível criar o usuário, tente novamente em instantes`,
    errorRemoveUser: `Não foi possível excluir o usuário, tente novamente em instantes`,
    loginIsRequired: `Login é obrigatório`,
    nameIsRequired: `Nome é obrigatório`,
    emailIsRequired: `Email é obrigatório`,
    profileIsRequired: `Perfil é obrigatório`,
    placeholders: {
      name: `Informe o nome do usuário`,
      login: `Informe o login do usuário`,
      profile: `Selecione um perfil`
    }
  },
  userRoles: {
    allUsers: `Todos os usuários`,
    attendance: `Atendimento`,
    backofficeAgent: `Agente Backoffice`,
    backofficeWalletAdmin: `Administrador de Wallets`,
    backofficeUsersAdmin: `Administrador de Perfis`
  },
  accountDetails: `Detalhes da conta`,
  accountHistory: `Histórico da conta`,
  walletDetails: `Detalhes da carteira`,
  dailyLimits: `Limites diários`,
  withdrawals: `Saques`,
  limits: `Limites`,
  limitsDailyTransactions: `Limite de transações diárias`,
  limitsDailyWithdraws: `Limite de saques diários`,
  limitsMonthlyWithdraws: `Limite de saques mensais`,
  dailyLimitTotal: `Limite diário total`,
  numberOfDailyTransactions: `Quantidade de transações diárias`,
  numberOfDailyWithdrawals: `Quantidade de saques diários`,
  numberOfMonthlyWithdrawals: `Quantidade de saques mensais`,
  totalMonthlyLimit: `Limite mensal total`,
  withdrawalFee: `Taxa de saque`,
  linkedCards: `Cartões vinculados`,
  appetizer: `Entradas`,
  departures: `Saídas`,
  apply: `Aplicar`,
  all: `Todas`,
  copy: `Copiar`,
  next: `Próximo`,
  publish: `Publicar`,
  finish: `Finalizar`,
  default: `Padrão`,
  invalidDate: `Data Inválida`,
  invalidCPF: `Preencha o CPF corretamente!`,
  invalidCNPJ: `Preencha o CNPJ corretamente!`,
  transactions: `Transações`,
  dailyTransactions: `Transações diárias`,
  schedules: `Agendamentos`,
  dateAndTime: `Data e Hora`,
  description: `Descrição`,
  value: `Valor`,
  transaction: `Transação`,
  transactionId: `ID da transação`,
  transactionData: `Dados da transação`,
  responseCode: `Código de resposta`,
  tracking: `Tracking`,
  establishmentCode: `Código do estabelecimento`,
  city: `Cidade`,
  country: `País`,
  terminal: `Terminal`,
  terminalId: `ID Terminal`,
  terminalCapacity: `Capacidade do terminal`,
  captureNetwork: `Rede de captura`,
  entryMode: `Entry mode`,
  entryModeDesc: `Descrição entry mode`,
  status: `Status`,
  actions: `Ações`,
  mcc: `Mcc`,
  mccs: `Mccs`,
  order: `Pedido`,
  orderCancel: `Cancelar pedido`,
  orderDate: `Data do Pedido`,
  creditDate: `Data de crédito:`,
  lastChangeDate: `Data da última alteração:`,
  requestDate: `Data da solicitação:`,
  seeDetails: `Ver Detalhes`,
  reverseTransaction: `Estornar transação`,
  transactionValue: `Valor da transação`,
  trustCredit: `Crédito Provisório`,
  creditReversal: `Reverter crédito`,
  transactionDetails: `Detalhes da transação`,
  allTransactions: `Todas as transações`,
  date: `Data`,
  establishment: `Estabelecimento`,
  codeAnswer: `Cód. resposta`,
  card: `Cartão`,
  operation: `Operação`,
  authorization: `Autorização`,
  reason: `Motivo`,
  creditValue: `Valor do crédito`,
  provisionalCreditValue: `Valor do crédito provisório`,
  damaged: `Danificado`,
  loss: `Perda`,
  theft: `Roubo`,
  suspectedFraud: `Suspeita de fraude`,
  priority: `Prioridade`,
  product: `Produto`,
  permissions: `Permissões`,
  model: `Modelo`,
  allowTransfer: `Permite transferência`,
  allowWithdraw: `Permite saque`,
  limitInRealBrazilian: `Limite em R$`,
  consumers: `Beneficiários`,
  consumer: `Beneficiário`,
  requestId: `ID do pedido`,
  cnpj: `CNPJ`,
  cpf: `CPF`,
  changeDate: `Alterar data`,
  confirmChangeDate: `Você deseja editar a data crédito do agendamento {{scheduleId}}?`,
  changeDateMessage: `Ao confirmar a nova data, os agendamentos de crédito dos {{totalConsumers}}
  beneficiários deste pedido serão atualizados.`,
  searchConsumerByCPF: `Buscar beneficiário por CPF`,
  showCancelledSchedules: `Mostrar agendamentos cancelados`,
  cancelSchedule: `Cancelar agendamento`,
  requestReversalTransaction: `Deseja solicitar o estorno da transação?
  Informe o motivo, código de ocorrência e o valor:`,
  trustCreditTransaction: `Deseja criar um crédito provisório para a transação? Por favor, informe o motivo e o valor abaixo:`,
  creditReversalTransaction: `Deseja realizar a reversão do crédito provisório para a transação? Por favor, informe o motivo:`,
  amountAvailable: `Valor disponibilizado em carteira {{value}}`,
  confirmAction: `Atenção ao executar esta ação, ela não poderá ser desfeita.`,
  occurrence: {
    sayNumber: `Informe o número da ocorrência`
  }
}

export const questions = {
  walletIsOpen: `Esta é uma carteira aberta?`
}

export const schedules = {
  cancel: `Você deseja cancelar o agendamento`,
  confirmCancel: `Ao confirmar o cancelamento os agendamentos de crédito dos {{consumersTotal}}
  beneficiários deste pedido serão cancelados.`,
  history: `Histórico do agendamento`
}
