import { type AcceptedStatus } from 'components/organisms/StatusLabel'
import { type MCC } from 'models/MCC'
import { type ResponseCode } from 'models/ResponseCode'
import { type WalletTransationTypes } from 'models/WalletTransaction'

export type TransactionCases = 'P' | 'E' | 'F' | 'U'

type FormatTransactionStatusProps = {
  status: TransactionCases
}

export const formatCreditCardNumber = (creditCardNumber: string) => {
  const first4Digits = creditCardNumber.substring(0, 4)
  const last4Digits = creditCardNumber.slice(-4)

  return `${first4Digits} •••• •••• ${last4Digits}`
}

export const formatTransactionStatus = ({
  status
}: FormatTransactionStatusProps) => {
  const formattedStatus = {
    P: 'PENDING',
    E: 'EXECUTED',
    F: 'FAILED',
    U: 'UNDOING'
  }

  return formattedStatus[status]
}

export const formatTransactionId = (value: string) => {
  const totalLenght = value.length

  if (totalLenght < 12) return value

  const firstNineLetters = value.substring(0, 9)
  const lastFourLetters = value.substring(totalLenght - 4, totalLenght)

  const formattedValue = `${firstNineLetters}...${lastFourLetters}`
  return formattedValue
}

type TranslateStatusToBrazilianProps = { value?: TransactionCases }

export const translateStatusToBrazilian = ({
  value
}: TranslateStatusToBrazilianProps) => {
  const formattedStatus = {
    P: 'Pendente',
    E: 'Aprovada',
    F: 'Negada',
    U: 'Desfeita'
  }

  return formattedStatus[value as TransactionCases] ?? ''
}

export type TransactionType = {
  type: WalletTransationTypes
}

export const formatTransactionType = ({ type }: TransactionType) => {
  const data = {
    PTX: 'Compra em estabelecimento',
    WTR: 'Transferência entre carteiras',
    CIT: 'Transação de crédito',
    DIT: 'Transação de débito',
    CTR: 'Estorno de transação',
    RFT: 'Estorno de transação',
    CBK: 'Transação de crédito externo/Cashback',
    WTX: 'Saque',
    WFT: 'Tarifa de Saque',
    PCR: 'Reversão crédito provisório',
    PRC: 'Crédito provisório de transação'
  }

  return data[type]
}

type FormatResponseCodeProps = {
  code: ResponseCode
}

export const formatResponseCode = ({ code }: FormatResponseCodeProps) => {
  const data = {
    '00': '0 - Transação aprovada',
    '01': '1 - Transação não autorizada. Entre em contato com sua instituição',
    '02': '2 - Transação parcial aprovada',
    '03': '3 - Código de estabelecimento inválido',
    '04': '4 - Via do cartão não confere',
    '06': '6 - Erro. Tente novamente',
    '07': '7 - Transação não realizada devido a falha técnica',
    '08': '8 - Temporariamente sem comunicação. Tente novamente mais tarde',
    '09': '9 - Transação em processamento',
    '10': '10 - Horário encerrado',
    '11': '11 - Transação não realizada',
    '12': '12 - Transação cancelada',
    '14': '14 - Cartão inválido',
    '15': '15 - Problemas na validação do token',
    '16': '16 - Saldo insuficiente',
    '18': '18 - Valor inválido',
    '19': '19 - Refaça a transação',
    '22': '22 - Conta encerrada',
    '23': '23 - Agência inexistente',
    '25': '25 - Transação não permitida para este tipo de conta',
    '26': '26 - Cartão cancelado',
    '27': '27 - Carteira pausada',
    '28': '28 - Transação não autorizada. Suspeita de fraude',
    '33': '33 - Transação não autorizada. Cartão vencido',
    '34': '34 - Conta bloqueada',
    '38': '38 - Senha bloqueada',
    '40': '40 - Dados inconsistentes',
    '44': '44 - Opção indisponível',
    '45': '45 - Estorno indevido',
    '46': '46 - Conta inexistente',
    '47': '47 - Senha expirada',
    '48': '48 - Sem comunicação com o sistema',
    '51': '51 - Saldo insuficiente',
    '55': '55 - Senha incorreta. O cartão será bloqueado após 3 tentativas falhas',
    '60': '60 - Entre em contato com sua instituição',
    '61': '61 - Limite diário excedido',
    '65': '65 - Serviço indisponível',
    '67': '67 - Cartão bloqueado. Entre em contato com sua instituição',
    '76': '76 - Cartão bloqueado. Entre em contato com sua instituição',
    '77': '77 - Mantém pendente',
    '78': '78 - Transação cancelada',
    '80': '80 - Transação inexistente',
    '96': '96 - Sistema indisponível',
    '97': '97 - Transação duplicada',
    '99': '99 - Sem resposta do autorizador'
  }

  return data[code]
}

export const formatMccDescription = (mcc: MCC) => {
  const data = {
    '742': 'Serviços Veterinários',
    '743': 'Produtor de Vinho',
    '744': 'Produtor de Champagne',
    '763': 'Cooperativas Agrícolas',
    '780': 'Serviços de Horticultura e Jardinagem',
    '1520': 'Empreiteiros em geral /Residencial e Com',
    '1711': 'Aquecedores / Refrigeradores / Soldagens',
    '1731': 'Empreiteiros elétricos',
    '1740': 'Serviços de Isolamento / Forro e Cobertu',
    '1750': 'Serviços de Carpintaria',
    '1761': 'Ferros / Metais / Coberturas - Fornecedo',
    '1771': 'Concreto - Serviços',
    '1799': 'Fornecedor Especializado Não Classificad',
    '2741': 'Diversos - Publicação e Impressão',
    '2791': 'Datilografia / Digitação e Serviços Rela',
    '2842': 'Limpezas Especiais / Polimento e Saneame',
    '3000': 'United Airlines',
    '3001': 'American Airlines',
    '3002': 'PAN AMERICAN WORLD AIRWAYS',
    '3003': 'EUROFLY',
    '3004': 'TRANS WORLD AIRLINES',
    '3005': 'British Airways',
    '3006': 'JAPAN AIRLINES',
    '3007': 'AIR FRANCE',
    '3008': 'Lufthansa',
    '3009': 'Air Canada',
    '3010': 'KLM ( Royal Dutch Airlines )',
    '3011': 'AEROFLOT',
    '3012': 'QUANTAS',
    '3013': 'Alitalia',
    '3014': 'SAUDI ARABIAN AIRLINES',
    '3015': 'SWISSAIR',
    '3016': 'SAS',
    '3017': 'South African Airways',
    '3018': 'Varig ( Brasil )',
    '3019': 'EASTERN AIRLINE',
    '3020': 'AIR INDIA',
    '3021': 'AIR ALGERIE',
    '3022': 'PHILIPPINE AIRLINES',
    '3023': 'Mexicana',
    '3024': 'PAKISTAN INTERNATIONAL',
    '3025': 'AIR NEW ZEALAND',
    '3026': 'EMIRATES AIRLINES',
    '3027': 'UTA',
    '3028': 'AIR MALTA',
    '3029': 'SN BRUSSELS AIRLINE (DELTA AIR TRANSPORT',
    '3030': 'Aerolineas Argentinas',
    '3031': 'OLYMPIC AIRWAYS',
    '3032': 'EL AL',
    '3033': 'ANSETT',
    '3034': 'AUSTRAINLIAN AIRLINES',
    '3035': 'TAP ( Portugal )',
    '3036': 'VASP',
    '3037': 'EGYPTAIR',
    '3038': 'KUWAIT AIRWAYS',
    '3039': 'AVIANCA',
    '3040': 'GULF AIR',
    '3041': 'BALKAN-AIR',
    '3042': 'FINNAIR',
    '3043': 'AER LINGUS',
    '3044': 'AIR LANKA',
    '3045': 'NIGERIA AIRWAYS',
    '3046': 'CRUZEIRO DO SUL',
    '3047': 'TURKISH AIRLINES',
    '3048': 'ROYAL AIR MAROC',
    '3049': 'TUNIS AIR',
    '3050': 'ICELANDAIR',
    '3051': 'AUSTRIAN AIRLINES',
    '3052': 'Lanchile',
    '3053': 'AVIACO',
    '3054': 'LADECO',
    '3055': 'Lab (Bolivia)',
    '3056': 'JET AIR',
    '3057': 'East / West Airlines (Austrália)',
    '3058': 'Delta',
    '3059': 'DBA AIRLINES',
    '3060': 'NORTHWEST AIRLINES',
    '3061': 'Continental',
    '3062': 'HAPAG LLOYD AIRLINES',
    '3063': 'US AIR',
    '3064': 'ADRIA AIRWAYS',
    '3065': 'AIRINTER',
    '3066': 'SOUTHWEST AIRLINES',
    '3067': 'VANGUARD AIRLINES',
    '3068': 'AIR ASTANA - AIRSTANA',
    '3069': 'SUN COUNTRY AIRLINES',
    '3070': 'FLY DUBAI',
    '3071': 'AIR BRITISH COLUMBIA',
    '3072': 'CEBU Pacific  - CEBU PAC',
    '3075': 'SINGAPORE AIRLINES',
    '3076': 'Aeromexico',
    '3077': 'THAI INTERNATIONAL',
    '3078': 'CHINA AIRLINES',
    '3079': 'JETSTAR AIRWAYS',
    '3080': 'AIRLINE SWOOP INC',
    '3081': 'XIAMEN AIRLINES',
    '3082': 'KOREAN AIRLINES',
    '3083': 'AIR AFRIQUE',
    '3084': 'EVA AIRWAYS',
    '3085': 'MIDWEST EXPRESS AIRLINES',
    '3086': 'CARNIVAL AIRLINES',
    '3087': 'METRO AIRLINES',
    '3088': 'CROATIAN AIRLINES',
    '3089': 'TRANSAERO',
    '3090': 'Unair',
    '3094': 'ZAMBIA AIRWAYS',
    '3096': 'AIR ZIMBABWE',
    '3097': 'SPANAIR',
    '3098': 'ASIANA AIRLINES',
    '3099': 'CATHAY PACIFIC',
    '3100': 'MALAYSIAN AIRLINE SYSTEM',
    '3102': 'IBERIA',
    '3103': 'GARUDA (INDONESIA)',
    '3106': 'BRAATHENS ASA',
    '3110': 'WINGS AIRWAYS',
    '3111': 'BRITISH MIDLAND',
    '3112': 'WINDWARD ISLAND',
    '3115': 'TOWER AIR',
    '3117': 'VIASA',
    '3118': 'VALLEY AIRLINES',
    '3125': 'TAN',
    '3126': 'TALAIR',
    '3127': 'TACA INTERNATIONAL',
    '3129': 'Surinam Airways',
    '3130': 'SUN WORLD INTERNATIONAL',
    '3131': 'VLM AIRLINES',
    '3132': 'FRONTIER AIRLINES',
    '3133': 'SUNBELT AIRLINES',
    '3135': 'SUDAN AIRWAYS',
    '3136': 'QATAR AIRWAYS',
    '3137': 'SINGLETON',
    '3138': 'SIMMONS AIRLINES',
    '3143': 'SCENIC AIRLINES',
    '3144': 'VIRGIN ATLANTIC AIRWAYS',
    '3145': 'SAN JUAN AIRLINES',
    '3146': 'LUXAIR',
    '3148': 'AIR LITTORAL',
    '3151': 'AIR ZAIRE',
    '3154': 'PRINCEVILLE',
    '3156': 'GO FLY AIRLINES',
    '3159': 'PROVINCETOWN-BOSTON AIRWAYS',
    '3161': 'ALL NIPPON AIRWAYS',
    '3164': 'NORONTAIR',
    '3165': 'NEW YORK HELICOPTER',
    '3167': 'AERO CONTINENTE',
    '3170': 'MOUNT COOK',
    '3171': 'Canadian Airlines International',
    '3172': 'NATIONAIR',
    '3174': 'JET BLUE AIRWAYS',
    '3175': 'MIDDLE EAST AIRLINES',
    '3176': 'METROFLIGHT AIRLINES',
    '3177': 'Airtran Airways',
    '3178': 'MESA AIR',
    '3180': 'WESTJET AIRLINES',
    '3181': 'MALEV',
    '3182': 'LOT POLISH AIRLINES',
    '3183': 'OMAN AIR',
    '3184': 'LIAT',
    '3185': 'LAV',
    '3186': 'LINEAS AEREAS PARAGUAYAS',
    '3187': 'Lacsa (Costa Rica)',
    '3188': 'VIRGIN EXPRESS',
    '3190': 'JUGOSLAV AIR',
    '3191': 'ISLAND AIRLINES',
    '3193': 'INDIAN AIRLINES',
    '3196': 'HAWAIIAN AIR',
    '3197': 'HAVASU AIRLINES',
    '3200': 'GUYANA AIRWAYS',
    '3203': 'GOLDEN PACIFIC AIR',
    '3204': 'FREEDOM AIRLINES',
    '3206': 'CHINA EASTERN AIRLINES',
    '3211': 'NORWEGIAN AIR SHUTTLE',
    '3212': 'DOMINICANA',
    '3213': 'BRAATHENS REGIONAL AIR ',
    '3215': 'DAN AIR SERVICES',
    '3216': 'CUMBERLAND AIRLINES',
    '3217': 'CSA CZECH AIRLINES',
    '3218': 'CROWN AIR',
    '3219': 'Copa',
    '3220': 'FAUCETT',
    '3221': 'TRANSPORTES AEROS MILITARES ECUATORIANOS',
    '3222': 'COMMAND AIRWAYS',
    '3223': 'COMAIR',
    '3226': 'Skyways Air',
    '3228': 'CAYMAN AIRWAYS',
    '3229': 'SAETA',
    '3231': 'SAHSA',
    '3233': 'CAPITOL AIR',
    '3234': 'CARIBBEAN AIRLINES- CARIBBEAN',
    '3235': 'BROCKWAY AIR',
    '3236': 'AIR ARABIA AIRLINE',
    '3238': 'BEMIDJI AIRLINES',
    '3239': 'Bar Harbor Airlines',
    '3240': 'BAHAMASAIR',
    '3241': 'AVIATECA',
    '3242': 'AVENSA',
    '3243': 'AUSTRIAN AIR SERVICE',
    '3245': 'EASYJET',
    '3246': 'RYANAIR',
    '3247': 'Gol Airlines',
    '3248': 'Tam Airlines',
    '3251': 'ALOHA AIRLINES - CANCELLED effective 10/',
    '3252': 'ALM',
    '3253': 'AMERICA WEST',
    '3254': 'U.S. AIR SHUTTLE',
    '3256': 'ALASKA AIRLINES',
    '3259': 'AMERICAN TRANS (ATA Air)  CANCELLED  eff',
    '3260': 'SPIRIT AIRLINES',
    '3261': 'AIR CHINA',
    '3262': 'RENO AIR',
    '3263': 'AERO SERVICIO CARABOBO',
    '3266': 'AIR SEYCHELLES',
    '3267': 'AIR PANAMA',
    '3280': 'AIR JAMAICA',
    '3282': 'AIR DJIBOUTI',
    '3284': 'AERO VIRGIN ISLANDS',
    '3285': 'AEROPERU',
    '3286': 'AEROLINEAS NICARAGUAENSIS',
    '3287': 'AERO COACH AVIATION',
    '3292': 'CYPRUS AIRWAYS',
    '3293': 'Equatoriana',
    '3294': 'ETHIOPIAN AIRLINES',
    '3295': 'KENYA AIRWAYS',
    '3296': 'AIR BERLIN',
    '3297': 'TAROM',
    '3298': 'AIR MAURITIUS',
    '3299': "WIDEROE'S FLYVESELSKAP",
    '3300': 'Azul Air',
    '3301': 'WIZZ AIRLINES',
    '3302': 'FLYBEAIR',
    '3303': 'TIGERAIR',
    '3308': 'CHINA SOUTHERN',
    '3351': 'Affiliated Auto Rental',
    '3352': 'AMERICAN INTL RENT-A-CAR',
    '3353': 'BROOKS RENT-A-CAR',
    '3354': 'ACTION AUTO RENTAL',
    '3355': 'SIXT CAR RENTAL',
    '3357': 'Hertz',
    '3359': 'PAYLESS CAR RENTAL',
    '3360': 'SNAPPY CAR RENTAL',
    '3361': 'AIRWAYS RENT-A-CAR',
    '3362': 'ALTRA AUTO RENTAL',
    '3364': 'AGENCY RENT-A-CAR',
    '3366': 'Budget Rent-A-Car',
    '3368': 'HOLIDAY',
    '3370': 'RENT-A-WRECK',
    '3374': 'ACCENT RENT-A-CAR',
    '3376': 'AJAX RENT-A-CAR',
    '3380': 'TRIANGLE RENT-A-CAR',
    '3381': 'EUROP CAR',
    '3385': 'TROPICAL RENT-A-CAR',
    '3386': 'SHOWCASE RENTAL CARS',
    '3387': 'Alamo Rent-A-Car',
    '3388': 'Merchants Rent a Car',
    '3389': 'Avis Rent-A-Car',
    '3390': 'Dollar Rent-A-Car',
    '3391': 'EUROPE BY CAR',
    '3393': 'National Car Rental',
    '3394': 'KEMWELL GROUP RENT-A-CAR',
    '3395': 'Thrifty Rent-A-Car',
    '3396': 'TILDEN',
    '3398': 'ECONO-CAR RENT-A-CAR',
    '3400': 'AUTO HOST CAR RENTALS',
    '3405': 'ENTERPRISE RENT-A-CAR',
    '3409': 'GENERAL RENT-A-CAR',
    '3412': 'A-1 RENT-A-CAR',
    '3414': 'GODFREY NATL RENT-A-CAR',
    '3420': 'AI ANSA',
    '3421': 'ALLSTATE RENT-A-CAR',
    '3423': 'AVCAR RENT-A-CAR',
    '3425': 'AUTOMATE RENT-A-CAR',
    '3427': 'AVON RENT-A-CAR',
    '3428': 'CAREY RENT-A-CAR',
    '3429': 'INSURANCE RENT-A-CAR',
    '3430': 'MAJOR RENT-A-CAR',
    '3431': 'REPLACEMENT RENT-A-CAR',
    '3432': 'RESERVE RENT-A-CAR',
    '3433': 'UGLY DUCKLING RENT-A-CAR',
    '3434': 'USA RENT-A-CAR',
    '3435': 'VALUE RENT-A-CAR',
    '3436': 'AUTOHANSA RENT-A-CAR',
    '3437': 'CITE RENT-A-CAR',
    '3438': 'INTERRENT',
    '3439': 'MILLEVILLE RENT-A-CAR',
    '3441': 'Advantage Rent-a-Car',
    '3501': 'Holiday Inn Express, Holiday Inns',
    '3502': 'Best Western Hotels',
    '3503': 'Sheraton Hotels',
    '3504': 'Hilton Hotels',
    '3505': 'Forte Hotels',
    '3506': 'Golden Tulip Hotels',
    '3507': 'FRIENDSHIP INNS',
    '3508': 'QUALITY INNS',
    '3509': 'Marriott',
    '3510': 'DAYS INN',
    '3511': 'ARABELLA HOTELS',
    '3512': 'Inter-Continental Hotels',
    '3513': 'Westin Hotels',
    '3514': 'AMERISUITES',
    '3515': 'RODEWAY INNS',
    '3516': 'LA QUINTA MOTOR INNS',
    '3517': 'Americana Hotels',
    '3518': 'Sol Hotels',
    '3519': 'PULLMAN',
    '3521': 'ROYAL LAHAINA RESORT',
    '3522': 'TOKYO HOTELS',
    '3523': 'PENINSULA',
    '3524': 'WELCOMGROUP HOTELS',
    '3525': 'DUNFEY HOTELS',
    '3526': 'PRINCE',
    '3527': 'DOWNTOWNERS-PASSPORT HOTEL',
    '3528': 'Red Lion Hotels , Red Lion Inns e Thunde',
    '3529': 'CP (CANADIAN PACIFIC)',
    '3530': 'Renaissance Hotels',
    '3531': 'KAUAI COCONUT BEACH RESORT',
    '3532': 'ROYAL KONA RESORT',
    '3533': 'Hotel Ibis',
    '3534': 'SOUTHERN PACIFIC',
    '3535': 'Hilton Internationals',
    '3536': 'AMFAC HOTELS',
    '3537': 'ANA HOTELS',
    '3538': 'CONCORDE',
    '3539': 'SUMMERFIELD SUITES HOTEL',
    '3540': 'IBEROTEL HOTELS',
    '3541': 'HOTEL OKURA',
    '3542': 'ROYAL HOTELS',
    '3543': 'FOUR SEASONS',
    '3544': 'CIGA',
    '3545': 'SHANGRI-LA',
    '3546': 'SIERRA SUITES HOTEL',
    '3547': 'BREAKERS RESORT, THE',
    '3548': 'Hoteles Melia',
    '3549': 'Auberge Des Governeurs',
    '3550': 'REGAL 8 INNS',
    '3551': 'Mirage Hotel and Casino',
    '3552': 'COAST HOTELS',
    '3553': 'Park Inns International',
    '3554': 'PINEHURST RESORT',
    '3555': 'TREASURE ISLAND HOTEL AND CASINO',
    '3556': 'BARTON CREEK RESORT',
    '3557': 'MANHATTAN EAST SUITE HOTELS',
    '3558': 'JOLLY',
    '3559': 'CANDLEWOOD SUITES',
    '3560': 'ALADDIN RESORT AND CASINO',
    '3561': 'GOLDEN NUGGET',
    '3562': 'Comfort Inns',
    '3563': 'Journey s End Motels',
    '3564': "SAM'S TOWN HOTEL AND CASINO",
    '3565': 'RELAX INNS',
    '3566': 'GARDEN PLACE HOTEL',
    '3567': 'SOHO GRAND HOTEL',
    '3568': 'LADBROKE HOTELS',
    '3569': 'TRIBECA GRAND HOTEL',
    '3570': 'FORUM HOTELS',
    '3571': 'GRAND WAILEA RESORT',
    '3572': 'MIYAKO-KINTETSU',
    '3573': 'SANDMAN HOTELS',
    '3574': 'VENTURE INNS',
    '3575': 'VAGABOND HOTELS',
    '3576': 'LA QUINTA RESORT',
    '3577': 'MANDARIN ORIENTAL',
    '3578': 'FRANKENMUTH BAVARIAN',
    '3579': 'Hotel Mercure',
    '3580': 'HOTEL DEL CORONADO',
    '3581': 'DELTA HOTELS',
    '3582': 'CALIFORNIA HOTEL AND CASINO',
    '3583': 'HOTEL RADISSON BLU',
    '3584': 'Princess Hotels International',
    '3585': 'HUNGAR HOTELS',
    '3586': 'SOKOS HOTELS',
    '3587': 'DORAL HOTELS',
    '3588': 'HELMSLEY HOTELS',
    '3589': 'DORAL GOLF RESORTS',
    '3590': 'FAIRMONT HOTELS',
    '3591': 'SONESTA',
    '3592': 'OMNI',
    '3593': 'CUNARD HOTELS',
    '3594': 'ARIZONA BILTMORE',
    '3595': 'HOSPITALITY INTERNATIONAL',
    '3596': 'WYNN LAS VEGAS',
    '3597': 'RIVERSIDE RESORT AND CASINO',
    '3598': 'REGENT INTERNATIONAL',
    '3599': 'PANNONIA HOTELS',
    '3600': 'SADDLEBROOK RESORT TAMPA',
    '3601': 'TRADE WINDS RESORTS',
    '3602': 'HUDSON HOTEL',
    '3603': "NOAH'S HOTELS",
    '3604': 'HILTON GARDEN INN',
    '3605': 'JURYS DOYLE HOTEL GROUP',
    '3606': 'JEFFERSON HOTEL',
    '3607': 'FONTAINEBLEAU RESORT',
    '3608': 'GAYLORD OPRYLAND',
    '3609': 'GAYLORD PALMS',
    '3610': 'GAYLORD TEXAN',
    '3611': 'C MON INN',
    '3612': 'MOVENPICK',
    '3613': 'MICROTEL INN & SUITES',
    '3614': 'AMERICINN',
    '3615': 'TRAVEL LODGE',
    '3616': 'HERMITAGE HOTEL',
    '3617': "AMERICA'S BEST VALUE INN",
    '3618': 'GREAT WOLF',
    '3619': 'ALOFT ( ALOFT HOTELS)',
    '3620': "BINION'S HORSESHOE CLUB",
    '3621': 'EXTENDED STAY',
    '3622': 'MERLIN HOTELS',
    '3623': 'DORINT',
    '3624': 'LADY LUCK HOTEL & CASINO',
    '3625': 'HOTEL UNIVERSALE',
    '3626': 'STUDIO PLUS',
    '3627': 'EXTENDED STAY AMERICA',
    '3628': 'EXCALIBUR HOTEL & CASINO',
    '3629': 'DAN HOTELS',
    '3630': 'TOKYU HOTELS',
    '3631': 'Sleep Inn',
    '3632': 'PHOENICIAN, THE',
    '3633': 'RANK',
    '3634': 'SWISSOTEL',
    '3635': 'RESO',
    '3636': 'SAROVA HOTELS',
    '3637': 'Ramada Inns Hotels',
    '3638': 'HO JO INN',
    '3639': 'MOUNT CHARLOTTE/THISTLE HOTELS',
    '3640': 'Hyatt Hotels',
    '3641': 'Sofitel Hotels',
    '3642': 'Novotel Hotels',
    '3643': 'STEIGENBERGER',
    '3644': 'ECONO LODGE',
    '3645': 'QUEENS MOAT HOUSES',
    '3646': 'SWALLOW HOTELS',
    '3647': 'HUSA HOTELS',
    '3648': 'DE VERE HOTELS',
    '3649': 'RADISSON HOTELS',
    '3650': 'RED ROOF INNS',
    '3651': 'IMPERIAL LONDON HOTEL',
    '3652': 'EMBASSY HOTELS',
    '3653': 'PENTA',
    '3654': 'LOEWS',
    '3655': 'SCANDIC',
    '3656': 'Sara Hotels',
    '3657': 'OBEROI',
    '3658': 'OTANI HOTLES',
    '3659': 'Taj Hotels International',
    '3660': 'KNIGHTS INNS',
    '3661': 'METROPOLE HOTELS',
    '3662': 'CIRCUS CIRCUS HOTEL AND CASINO',
    '3663': 'HOTELES EL PRESIDENTE',
    '3664': 'FLAG INNS',
    '3665': 'HAMPTON INNS',
    '3666': 'STAKIS',
    '3667': 'LUXOR HOTEL AND CASINO',
    '3668': 'MARITIM',
    '3669': 'ELDORADO HOTEL & CASINO',
    '3670': 'ARCADE HOTELS',
    '3671': 'ARCTIA HOTELS',
    '3672': 'CAMPANILE HOTELS',
    '3673': 'IBUSZ HOTELS',
    '3674': 'RANTASIPI HOTELS',
    '3675': 'INTERHOTEL CEDOK',
    '3676': 'MONTE CARLO HOTEL AND CASINO',
    '3677': 'CLIMAT DE FRANCE HOTELS',
    '3678': 'CUMULUS HOTELS',
    '3679': 'SILVER LEGACY HOTEL AND CASINO',
    '3680': 'Hoteis Othan',
    '3681': 'ADAMS MARK HOTELS',
    '3682': 'SAHARA HOTEL AND CASINO',
    '3683': 'BRADBURY SUITES',
    '3684': 'BUDGET HOST INN',
    '3685': 'BUDGETEL INNS',
    '3686': 'SUISSE CHALETS',
    '3687': 'CLARION',
    '3688': 'COMPRI HOTELS',
    '3689': 'CONSORT HOTELS',
    '3690': 'COURTYARD BY MARRIOT',
    '3691': 'DILLON INNS',
    '3692': 'DOUBLE TREE HOTELS',
    '3693': 'DRURY INNS',
    '3694': 'ECONOMY INNS OF AMERICA',
    '3695': 'EMBASSY SUITES',
    '3696': 'EXEL INNS',
    '3697': 'FAIRFIELD HOTELS',
    '3698': 'HARLEY HOTELS',
    '3699': 'MIDWAY MOTOR LODGE',
    '3700': 'Motel 6',
    '3701': 'LA MANSION DEL RIO',
    '3702': 'REGISTRY HOTEL, THE',
    '3703': 'Residence Inns',
    '3704': 'ROYCE HOTELS',
    '3705': 'SANDMAN INNS',
    '3706': 'SHILO INNS',
    '3707': "SHONEY'S INNS",
    '3708': 'VIRGIN RIVER HOTEL AND CASINO',
    '3709': 'SUPER 8 MOTELS',
    '3710': 'The Ritz-Carlton',
    '3711': 'FLAGS INN ( AUSTRALIA)',
    '3712': "BUFFALO BILL'S HOTEL AND CASINO",
    '3713': 'QUALITY PACIFIC HOTEL',
    '3714': 'FOUR SEASONS HOTEL (AUSTRALIA)',
    '3715': 'FAIRFIELDS INNS',
    '3716': 'Carlton Hotels',
    '3717': 'CITY LODGE HOTELS',
    '3718': 'KAROS HOTELS',
    '3719': 'PROTEA HOTELS',
    '3720': 'SOUTHERN SUN HOTELS',
    '3721': 'CONRAD HOTELS',
    '3722': 'WYNDHAM HOTELS',
    '3723': 'RICA HOTELS',
    '3724': 'INTER NOR HOTELS',
    '3725': 'SEAPINES PLANTATION',
    '3726': 'RIO SUITES',
    '3727': 'BROADMOOR HOTEL',
    '3728': "BALLY'S HOTEL AND CASINO",
    '3729': "JOHN ASCUAGA'S NUGGET",
    '3730': 'MGM GRAND HOTEL',
    '3731': "HARRAH'S HOTELS & CASINO",
    '3732': 'OPRYLAND HOTEL',
    '3733': 'BOCA RATON RESORT',
    '3734': 'HARVEY/BRISTOL HOTELS',
    '3735': 'MASTERS ECONOMY INNS',
    '3736': 'COLORADO BELLE/EDGEWATER RESORT',
    '3737': 'RIVIERA HOTEL AND CASINO',
    '3738': 'TROPICANA RESORT & CASINO',
    '3739': 'WOODSIDE HOTELS & RESORT',
    '3740': 'TOWNEPLACE SUITES',
    '3741': 'MILLENNIUM BROADWAY HOTEL',
    '3742': 'Club Med',
    '3743': 'BILTMORE HOTEL & SUITES',
    '3744': 'CAREFREE RESORTS',
    '3745': 'ST. REGIS HOTEL',
    '3746': 'ELIOT HOTEL',
    '3747': 'CLUBCORP/CLUBRESORTS',
    '3748': 'WELLESLEY INNS',
    '3749': 'BEVERLY HILLS HOTEL, THE',
    '3750': 'Crowne Plaza Hotels',
    '3751': 'HOMEWOOD SUITES',
    '3752': 'PEABODY HOTELS',
    '3753': 'GREENBRIAR RESORTS',
    '3754': 'AMELIA ISLAND PLANTATION',
    '3755': 'HOMESTEAD, THE',
    '3756': 'TOYOKO INN',
    '3757': 'CANYON RANCH',
    '3758': 'KAHALA MANDARIN ORIENTAL HOTEL',
    '3759': 'ORCHID AT MAUNA LANI',
    '3760': 'HALEKULANI HOTEL/WAIKIKI PARC',
    '3761': 'PRIMADONNA HOTEL AND CASINO',
    '3762': "WHISKEY PETE'S HOTEL AND CASINO",
    '3763': 'CHATEUA ELAN WINERY & RESORT',
    '3764': 'BEAU RIVAGE HOTEL & CASINO',
    '3765': 'BELLAGIO HOTEL & CASINO',
    '3766': 'FREMONT AND CASINO',
    '3767': 'MAIN STREET STATION HOTEL AND CASINO',
    '3768': 'SILVER STAR HOTEL AND CASINO',
    '3769': 'STRATOSHPHERE HOTEL AND CASINO',
    '3770': 'SPRING HILL SUITES',
    '3771': 'Caesar s Hotel and Casino',
    '3772': 'NEMACOLIN WOODLANDS',
    '3773': 'VENETIAN RESORT HOTEL AND CASINO',
    '3774': 'NEW YORK NEW YORK HOTEL AND CASINO',
    '3775': 'SANDS RESORT',
    '3776': 'NEVELE GRANDE RESORT AND COUNTRY CLUB',
    '3777': 'MANDALAY BAY RESORT',
    '3778': 'FOUR POINTS HOTELS',
    '3779': 'W HOTELS',
    '3780': 'DISNEY RESORTS',
    '3781': 'PATRICIA GRAND RESORT HOTELS, THE',
    '3782': 'ROSEN HOTELS AND RESORTS',
    '3783': 'TOWN AND COUNTRY RESORT & CONVENTION CEN',
    '3784': 'FIRST HOSPITALITY HOTELS',
    '3785': 'OUTRIGGER HOTELS AND RESORTS',
    '3786': 'OHANA HOTELS OF HAWAII',
    '3787': 'CARIBE ROYALE RESORT SUITES & VILLAS',
    '3788': 'ALA MOANA HOTEL',
    '3789': "SMUGGLER'S NOTCH RESORT",
    '3790': 'RAFFLES HOTELS',
    '3791': 'Staybridge Suites',
    '3792': 'CLARIDGE CASINO HOTEL',
    '3793': 'FLAMINGO HOTELS',
    '3794': 'GRAND CASINO HOTELS',
    '3795': 'Paris Las Vegas Hotel',
    '3796': 'PEPPERMILL HOTEL CASINO',
    '3797': 'ATLANTIC CITY HILTON',
    '3798': 'EMBASSY VACATION RESORT',
    '3799': 'HALE KOA HOTEL',
    '3800': 'HOMESTEAD SUITES',
    '3801': 'WILDERNESS HOTEL AND GOLF RESORT',
    '3802': 'THE PALACE HOTEL',
    '3803': 'THE WIGWAM GOLF RESORT AND SPA',
    '3804': 'THE DIPLOMAT COUNTRY CLUB AND SPA',
    '3805': 'THE ATLANTIC',
    '3806': 'PRINCEVILLE RESORT',
    '3807': 'ELEMENT',
    '3808': 'LXR (Luxury Resorts)',
    '3809': 'SETTLE INN',
    '3810': 'LA COSTA RESORT',
    '3811': 'PREMIER TRAVEL INNS',
    '3812': 'HYATT PLACE',
    '3813': 'HOTEL INDIGO',
    '3814': 'THE ROOSEVELT HOTEL NY',
    '3815': 'Holiday Inn Nickelodeon',
    '3816': 'Real Estate Agents',
    '3817': 'Affinia',
    '3818': 'MainStay Suites',
    '3819': 'Oxford Suites',
    '3820': 'Jumeriah Essex House',
    '3821': 'Caribe Royal (effective Oct 16, 2009) ',
    '3822': 'CROSSLAND',
    '3823': 'Grand Sierra Resort ',
    '3824': 'ARIA',
    '3825': 'VDARA',
    '3826': 'AUTOGRAPH',
    '3827': 'GALT HOUSE',
    '3828': 'COSMOPOLITAN OF LAS VEGAS',
    '3829': 'COUNTRY INN BY CARLSON',
    '3830': 'PARK PLAZA HOTEL',
    '3831': 'WALDORF',
    '3832': 'CURIO HOTELS',
    '3833': 'BAYMONT INN & SUITES',
    '3834': 'DOLCE HOTELS',
    '3835': 'HAWTHORNE SUITES BY WINDHAM',
    '3836': 'HOSHINO RESORTS',
    '3837': 'KIMPTON HOTELS',
    '3838': 'KIMPTON HOTELS',
    '3843': 'Serviços Cargueiros',
    '3844': 'Serviços de Mensagens',
    '3851': 'Combustível de Aviões',
    '3852': 'Aluguel de Aviões',
    '3854': 'Taxi aereo',
    '3856': 'Vendas em Voo',
    '3880': 'Programa Diners Club',
    '3886': 'Cartão de Recarga de Telefone',
    '3887': 'Telefones Aereos',
    '3897': 'Consultorias',
    '4011': 'Estrada de Ferro / Railroads',
    '4111': 'Transportes Urbanos - Incluindo Balsa',
    '4112': 'Trem de Passageiros',
    '4119': 'Serviços de Ambulância',
    '4121': 'Táxi e Limusine',
    '4131': 'Linhas de Ônibus',
    '4214': 'Motor Freight Carriers and Trucking-Loca',
    '4215': 'Courier',
    '4225': 'Armazém Público',
    '4411': 'Cruzeiros Marítimos',
    '4457': 'Aluguel e Arrendamento de Navios',
    '4468': 'Serviços e Suprimentos Naúticos',
    '4511': 'Transporte de Carga Aérea',
    '4582': 'Aeroportos',
    '4583': 'Lounges em Aeroportos',
    '4722': 'Agências de Viagem / Operadoras de Turis',
    '4723': 'Operadores de pacote de turismo',
    '4761': 'Telemarketing Relacionado a Viagens',
    '4784': 'Pedágio de Estradas - MCC Original Pedág',
    '4789': 'Cooperativas de Taxi',
    '4812': 'Equip. de Telecomunicação / Incluindo Ve',
    '4813': 'Fornecedores de serviços de telecomunica',
    '4814': 'Fax / Chamadas Locais e Longa Distância',
    '4815': 'Resumo Mensal de Cobranças Telefônicas',
    '4816': 'Rede de computadores/ Provedores',
    '4821': 'Serviço Telegráfico',
    '4829': 'Dinheiro de Transferência Eletrônica',
    '4899': 'TV a Cabo, Satélite, e outros tipos de T',
    '4900': 'Serviços Públicos ( Água, Luz, Gás e Tel',
    '5013': 'Peças e Serviços para Veículos',
    '5021': 'Móveis Comerciais e para Escritórios',
    '5039': 'Materiais de Construção Sem Classificaçã',
    '5044': 'Revelação / Fotocópia e Microfilmagem',
    '5045': 'Equipamentos e Periféricos / Computadore',
    '5046': 'Comércio de Equipamentos Não Classificad',
    '5047': 'Equip. e Suprimentos para Hospitais e Co',
    '5051': 'Centros de Serviços e Oficinas de Metal',
    '5065': 'Equipamentos e Peças Eletrônicas',
    '5072': 'Suprimentos e Equipamentos de Ferragens',
    '5074': 'Suprimentos e Equipamentos para Aquecime',
    '5085': 'Suprimentos Industriais Não Classificado',
    '5094': 'Miscelâneos, Metais , Pedras Preciosas,',
    '5099': 'Materiais Duráveis Não Classificados',
    '5111': 'Materiais de Escritório',
    '5122': 'Farmácias e Farmacêuticos (Manipilação)',
    '5131': 'Pequenos Objetos - Armarinhos',
    '5137': 'Comércio de Roupas e Uniformes - Adulto',
    '5139': 'Sapataria',
    '5169': 'Produtos Químicos e Aliados Não Classifi',
    '5172': 'Petróleo e Derivados',
    '5192': 'Livros, Periódicos e Jornais',
    '5193': 'Suprimentos para Floristas',
    '5198': 'Tintas, Vernizes e Suprimentos',
    '5199': 'Material de Consumo - Não Classificado A',
    '5200': 'Home Supply Warehouse Stores',
    '5211': 'Lojas de Material de Construção',
    '5231': 'Lojas de Vidro / Lojas de Pintura / Pape',
    '5251': 'Lojas de Ferragens',
    '5261': 'Lojas de Suprimentos para Jardins e Estu',
    '5262': 'Marketplaces',
    '5271': 'Vendedores Ambulantes',
    '5300': 'Atacadista',
    '5309': 'Comércio Livre de Importados/Duty free',
    '5310': 'Lojas de Descontos',
    '5311': 'Lojas de Departamento',
    '5331': 'Lojas de Variedades',
    '5399': 'Miscelâneas - Comércio em Geral',
    '5411': 'Mercearias e Supermercados',
    '5422': 'Comidas Congeladas',
    '5441': 'Docerias e Confeitarias',
    '5451': 'Laticínios',
    '5462': 'Padarias',
    '5499': 'Miscelâneas - Conveniência / Delicatesse',
    '5511': 'Carros e Caminhões ( Novos e Usados ) Al',
    '5521': 'Vendedores de Automóveis Usados',
    '5531': 'Lojas automobilísticas e de suprimentos ',
    '5532': 'Loja de Pneus',
    '5533': 'Lojas de Acessórios para Veículos Automo',
    '5541': 'Postos de Gasolina',
    '5542': 'Fuel Dispenser, Automated',
    '5551': 'Vendedor de Barcos',
    '5552': 'carga de veículos elétricos',
    '5561': 'Utilidades para Trailers',
    '5571': 'Vendedor de Motocicletas',
    '5592': 'Vendedor de Trailers',
    '5598': 'Venda de Motos de Neve',
    '5599': 'Miscelâneas - Automóveis, aeronaves, não',
    '5611': 'Lojas de Roupas Masculinas',
    '5621': 'Lojas de Roupas Femininas',
    '5631': 'Acessórios Femininos Especiais',
    '5641': 'Roupas Infantis',
    '5651': 'Vendas de Roupas para Família',
    '5655': 'Artigos Esportivos',
    '5661': 'Lojas de Sapatos',
    '5681': 'Artigos e Acessórios de Pele',
    '5691': 'Roupas Masculinas e Femininas',
    '5697': 'Alfaiates / Costureiras (os)',
    '5698': 'Loja de Perucas',
    '5699': 'Miscelâneas - Acessórios e Roupas Mascul',
    '5711': 'Vendas de Equipamentos de Fazenda',
    '5712': 'Mobília e equipamentos, exceto equip. el',
    '5713': 'Revestimento (Pisos)',
    '5714': 'Tapeçaria / Cortinas',
    '5715': 'Atacadista de Bebidas Alcoólicas',
    '5718': 'Lareiras e Acessórios',
    '5719': 'Mobília e Utilidades para o Lar',
    '5722': 'Lojas de Utensílios - Não Classificados',
    '5732': 'Aparelhos de Som / Televisores / Eletrod',
    '5733': 'Instrumentos Musicais',
    '5734': 'Lojas de Informática - Software',
    '5735': 'Discos',
    '5811': 'Fornecedores de Alimentos ou Matimentos',
    '5812': 'Restaurantes',
    '5813': 'Bares / Discotecas / Casas Noturnas',
    '5814': 'Lanchonete',
    '5815': 'Produtos Digitais - Media audiovisual',
    '5816': 'PRODUTOS DIGITAIS - JOGOS',
    '5817': 'PROD DIG - aplic e soft (exceto jogos)',
    '5818': 'PRODUTOS DIGITAIS - DIVERSAS CATEGORIAS',
    '5912': 'Drogarias / Farmácias',
    '5921': 'Lojas de Bebidas - Cervejas, vinhos e Li',
    '5931': 'Artigos de Segunda Mão',
    '5932': 'Antiguidades / Venda / Conserto',
    '5933': 'Penhores',
    '5935': 'Demolição/Salvamento de naufrágios',
    '5937': 'Reprodução de Antiguidades',
    '5940': 'Bicicletas - Vendas e Serviços',
    '5941': 'Material Esportivos',
    '5942': 'Livrarias',
    '5943': 'Papelarias',
    '5944': 'Joalherias e Relojoarias',
    '5945': 'Brinquedos e Jogos',
    '5946': 'Câmeras Fotográficas e Acessórios',
    '5947': 'Presentes, cartões, lembranças (souvenir',
    '5948': 'Bagagem e materiais de couro',
    '5949': 'Armarinhos / Tecidos',
    '5950': 'Vidros / Cristais',
    '5960': 'Marketing Direto - Serviços de Seguro',
    '5961': 'Clube de Discos e Livros',
    '5962': 'MKT Direto e Prestação de Serviços Relac',
    '5963': 'Vendas em Domicílio',
    '5964': 'Vendas por Catálogos',
    '5965': 'Catálogos de Lojas de Varejo e Venda por',
    '5966': 'Telemarketing Ativo : E.C. Contacta o Cl',
    '5967': 'Audiotexto / Videotexto acessado Fax / T',
    '5968': 'Assinatura contínua',
    '5969': 'Outros EC s de Telemarketing, não classi',
    '5970': 'Loja de Produtos para Artistas',
    '5971': 'Galerias de Arte / Leilão de Arte',
    '5972': 'Numismática / Lojas de Selo / Suprimento',
    '5973': 'Artigos Religiosos',
    '5974': 'Loja de Carimbos',
    '5975': 'Aparelhos Auditivos',
    '5976': 'Materiais Ortopédicos',
    '5977': 'Cosméticos',
    '5978': 'Máquina de Escrever - Venda, Serviço e A',
    '5983': 'Comércio de Combustíveis / Carvão e Lenh',
    '5992': 'Floricultura',
    '5993': 'Tabacaria',
    '5994': 'Banca de Jornais',
    '5995': 'Loja de Animais/Comidas e Suprimentos pa',
    '5996': 'Piscinas / Vendas / Serviços e Supriment',
    '5997': 'Lojas de Barbeadores Elétricos - Serviço',
    '5998': 'Barracas e Toldos',
    '5999': 'Lojas de varejo diversas e especializada',
    '6010': 'Holdings de instituições financeiras',
    '6011': 'Instituições Financeiras',
    '6012': 'Bancos / Lojas de Poupança e Instituiçõe',
    '6050': 'Quasi Cash-Instituição Financeira de Cli',
    '6051': 'Casas de Câmbio / Travelers Checks',
    '6211': 'Corretor de Seguros',
    '6300': 'Seguradoras',
    '6381': 'Seguros Premiums',
    '6399': 'Seguros',
    '6513': 'Corretores imobiliários e Gerentes e Ger',
    '6531': 'Recebimento de fundos – P2P',
    '6532': 'Sist de Pgto para Fornecedor de Serviços',
    '6533': 'Sist de Pgto para Fornecedor de Serviços',
    '6534': 'Dinheiro de Transferência Eletrônica',
    '6538': 'Retirada de Fundos - P2P',
    '6540': 'Recarga',
    '6611': 'Pagamentos Excessivos',
    '7011': 'Hotéis /motéis e outros não classificado',
    '7012': 'Compartilhamento de Casas para Férias',
    '7032': 'Acampamentos - Meninas e Meninos',
    '7033': 'Estacionamento de trailers',
    '7210': 'Lavanderias',
    '7211': 'Serviço de Lavanderia - Comercial e fami',
    '7216': 'Tinturaria',
    '7217': 'Limpeza de Carpetes e Tapetes',
    '7221': 'Estúdio Fotográfico',
    '7230': 'Salão de Beleza / Barbearia',
    '7251': 'Conserto de Chapéus / Sapatos',
    '7261': 'Crematórios / Funerárias',
    '7273': 'Acompanhante',
    '7276': 'Serviço de Preparação de Taxas e Tributo',
    '7277': 'Advogados - Serviços de orientação',
    '7278': 'Clube de Compras',
    '7280': 'Fundo pessoal do Paciente para Hospital',
    '7296': 'Aluguel de Roupas',
    '7297': 'Casas de Massagem',
    '7298': 'Clínicas de Beleza - SPA',
    '7299': 'Miscelâneas - Serviços personalizados nã',
    '7311': 'Anúncios / Classificados / Propaganda',
    '7321': 'Agências de Crédito ao Comsumidor',
    '7322': 'Agências de Cobranças de Débitos',
    '7332': 'Projetos Blueprints',
    '7333': 'Gráficas',
    '7338': 'Cópias / Fotocópias',
    '7339': 'Serviços de Taquigrafia e Secretariado',
    '7342': 'Dedetização',
    '7349': 'Serviços de Manutenção e Conservação',
    '7361': 'Agências de Empregos e Serviços Temporár',
    '7372': 'Serviços de Processamentos de Dados',
    '7375': 'Serviços de Informações',
    '7379': 'Conserto e Manutenção de Computadores',
    '7392': 'Serviços de Administração e Consultoria',
    '7393': 'Agências de Detetives/Carros Blindados e',
    '7394': 'Aluguel de Equipamentos e mobílias',
    '7395': 'Laboratórios Fotográficos',
    '7399': 'Serviços Comerciais Não Classificados An',
    '7512': 'Locadoras de Veículos',
    '7513': 'Locadoras de Caminhões e Trailers',
    '7519': 'Aluguel de Veículos Recreativos',
    '7523': 'Estacionamento de Automóveis',
    '7531': 'Funilaria / Lanternagem',
    '7534': 'Borracheiro',
    '7535': 'Oficina de Pintura de Automóveis',
    '7538': 'Oficinas',
    '7542': 'Lava Rápido',
    '7549': 'Reboque',
    '7622': 'Conserto - Rádio / TV / Aparelho de Som',
    '7623': 'Conserto - Ar Condicionado / Refrigeraçã',
    '7629': 'Consertos Elétricos',
    '7631': 'Conserto - Jóias e Relógios',
    '7641': 'Reparo e Restauração de Móveis',
    '7692': 'Soldagens',
    '7699': 'Miscelâneas Lojas de Reparos e Serviços',
    '7801': 'Cassinos Licensiados pelo Governo',
    '7802': 'Corrida de Cavalo/Cachorros Licenciados',
    '7829': 'Produção e Distribuição de Vídeo Tape',
    '7832': 'Cinemas',
    '7833': 'Teatros de Cinemas',
    '7841': 'Locadoras de Vídeo',
    '7911': 'Dança ( Academias )',
    '7922': 'Produções Artísticas - Ingressos',
    '7929': 'Bandas , Orquestras - Serviços Relaciona',
    '7932': 'Casas de Bilhar',
    '7933': 'Boliche',
    '7941': 'Campo de Esportes / Clubes',
    '7991': 'Quiosques - MCC Original-Atrações Turist',
    '7992': 'Campo de golfe',
    '7993': 'Suprimentos diversão em vídeos games',
    '7994': 'Vídeo Games - Lojas',
    '7995': 'Casa Lotéricas, Bingos e Cassinos',
    '7996': 'Parques de Diversões / Espetáculos Circe',
    '7997': 'Clubes / Atletismo - Sócios',
    '7998': 'Aquarius / Museu Aquático',
    '7999': 'Diversão e Recreação - Zoológico -',
    '8011': 'Médicos e Clinicas',
    '8021': 'Dentistas / Ortodondistas - Clínicas',
    '8031': 'Osteopata - Clínicas',
    '8041': 'Chiropractors',
    '8042': 'Oftalmologistas - Clínicas',
    '8043': 'Óticas / Produtos Óticos',
    '8044': 'Bens óticos e para óculos',
    '8049': 'Pedicuro',
    '8050': 'Casas de Repouso / Serviços de Enfermage',
    '8062': 'Hospitais',
    '8071': 'Laboratórios Médicos / Dentários',
    '8082': 'HOME HEALTH CARE SERVICES',
    '8099': 'Serviço Médico e Prof.Saúde-Não Classifi',
    '8111': 'Serviços Legais',
    '8211': 'Escolas 1º e 2º Graus',
    '8220': 'Universidades',
    '8241': 'Escolas por Correspondências',
    '8244': 'Serviços Escolares',
    '8249': 'Escola Vocação e Ocupação',
    '8299': 'Serviços Educacionais - Não Classificado',
    '8351': 'Creches',
    '8398': 'Organizaçòes de Caridade e Serviços Soci',
    '8641': 'Associação Civica e Social',
    '8651': 'Organização Política',
    '8661': 'Organização Religiosa',
    '8675': 'Associação Automobilística',
    '8699': 'Sindicato Trabalhista e Organizações Pro',
    '8734': 'Testes Laboratoriais',
    '8911': 'Serviços de pesquisa para Arquitetura e',
    '8931': 'Serviços de Auditoria e Contabilidade',
    '8999': 'Serviços Profissionais - Não Relacionado',
    '9211': 'Pensão Alimentícia',
    '9222': 'Multas',
    '9223': 'Segurança e ordem pública',
    '9311': 'Pagamentos de Taxas',
    '9399': 'Serviços governamentais - Não classifica',
    '9401': 'Vale-Refeição',
    '9402': 'Serviços Postais (apenas públicos)',
    '9405': 'BNDES',
    '9406': 'Loterias Governamentais',
    '9950': 'Compras entre Empresas',
    '9999': 'Segmento Padrão'
  }

  const formattedDescription = `${data[mcc] ? `- ${data[mcc]}` : ''}`

  return `${mcc} ${formattedDescription}`
}

export const getFormattedStatus = (status: AcceptedStatus) => {
  const data = {
    ALL: 'Todos',
    ACTIVE: 'Ativa',
    INACTIVE: 'Inativa',
    DRAFT: 'Rascunho',
    BLOCKED: 'Bloqueado',
    PRE_BLOCKED: 'Aguardando',
    CANCELLED: 'Cancelado',
    WAITING_CANCELLATION: 'Cancelado',
    CLOSED: 'Fechado',
    FAILED: 'Negada',
    REJECTED: 'Negada',
    CANCELED: 'Cancelado',
    PENDING: 'Pendente',
    EXECUTED: 'Aprovada',
    PROCESSED: 'Disponibilizado',
    SCHEDULED: 'Agendado',
    CREATED: 'Processando',
    ACCEPTED: 'Disponibilizado',
    PASSWORD_BLOCKED: 'Bloqueado',
    UNDOING: 'Desfeita'
  }

  return data[status]
}
