import { Center, MenuButton as ChakraMenuButton, Image } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { PluxeeMenu } from 'components/ui'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import React from 'react'

export const ExitsOptions = ({
  data,
  setWalletId,
  onOpenDetails,
  onOpenProvisionalCredit,
  setSelectedTransaction,
  isShowProvisionalCredit,
  isShowReverseTransaction,
  onOpenReverseTransaction
}: {
  data: WalletTransaction
  setWalletId?: () => void
  setSelectedTransaction: (data: WalletTransaction) => void
  onOpenDetails: () => void

  onOpenProvisionalCredit: () => void
  isShowProvisionalCredit: boolean

  onOpenReverseTransaction: () => void
  isShowReverseTransaction: boolean
}) => {
  return (
    <PluxeeMenu
      menuButton={<CustomMenuButton />}
      items={[
        {
          title: 'Ver Detalhes',
          onClick: () => {
            setWalletId && setWalletId()
            setSelectedTransaction(data)
            onOpenDetails()
          },
          isDisabled: false,
          icon: <Icon name="search" />
        },
        {
          title: 'Crédito provisório',
          isDisabled: !isShowProvisionalCredit,
          icon: (
            <Image
              w="24px"
              h="24px"
              src="/img/pluxee/provisional.svg"
              alt="Icon"
            />
          ),
          onClick: () => {
            setWalletId && setWalletId()
            setSelectedTransaction(data)
            onOpenProvisionalCredit()
          }
        },
        {
          title: translate('commons.reverseTransaction'),
          isDisabled: !isShowReverseTransaction,
          onClick: () => {
            setWalletId && setWalletId()
            setSelectedTransaction(data)
            onOpenReverseTransaction()
          },
          icon: (
            <Image
              w="24px"
              h="24px"
              src="/img/pluxee/refundIcon.svg"
              alt="Refund Icon"
            />
          )
        }
      ]}
    />
  )
}

const CustomMenuButton = () => (
  <ChakraMenuButton
    w="40px"
    h="40px"
    borderRadius="0"
    background="transparent"
    color="#1B51DC"
    _active={{
      border: '2px solid #1B51DC'
    }}
    _focusVisible={{
      border: '3px solid #1B51DC',
      color: '#0F266D'
    }}
    _hover={{
      background: 'transparent',
      color: '#0F266D'
    }}
  >
    <Center>
      <Icon name="more_horiz" color="inherit" />
    </Center>
  </ChakraMenuButton>
)
