import { Tag } from '@chakra-ui/react'
import { Divider, Flex, Text, VStack } from 'components/atoms'
import {
  CloseButton,
  Modal,
  TransactionStatus,
  TransactionHistory
} from 'components/ui'
import { type WalletTransaction } from 'models/WalletTransaction'
import React from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence } from 'utils/stringUtils'
import {
  formatCreditCardNumber,
  formatMccDescription,
  formatResponseCode,
  formatTransactionType
} from 'utils/transactionsUtils'

type TransactionDetailsModalProps = {
  isOpen: boolean
  onClose: () => void
  transaction: WalletTransaction
  isOutboundTransaction?: boolean
}

export const TransactionDetailsModal = ({
  isOpen,
  onClose,
  transaction,
  isOutboundTransaction = false
}: TransactionDetailsModalProps) => {
  const tagTitle = transaction.voucherTransaction ? 'Voucher' : 'Crédito'

  const formattedCardType = transaction.isVirtualCard
    ? 'Cartão virtual'
    : 'Cartão físico'

  const formattedCardNumber = transaction.cardNumber
    ? formatCreditCardNumber(transaction.cardNumber)
    : '-'

  const formattedWithdrawFeeDesc =
    isOutboundTransaction && transaction.type === 'WFT'
      ? transaction.description
      : capitalizeSentence(transaction.description ?? '')

  const modalFooter = <CloseButton onClick={onClose} />

  return (
    <Modal
      heading="Visão detalhada"
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      decreaseMarginTop
    >
      <VStack spacing="xxs">
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Flex flexDir="column" gap="8px">
            <Text fontSize="14px" fontWeight="500">
              ID da transação:
            </Text>
            <Text color="#1B51DC" fontSize="24px" fontWeight="700">
              {transaction.transactionUUID}
            </Text>
            <Flex color="#5A5469" gap="8px" fontSize="14px">
              <Text fontWeight="700">NSU:</Text>
              <Text fontWeight="500">{transaction.nsu ?? '-'}</Text>
            </Flex>
          </Flex>

          {transaction.status && (
            <Flex>
              <TransactionStatus status={transaction.status} />
            </Flex>
          )}
        </Flex>

        <Divider />

        <Flex w="100%" gap="24px">
          <Flex flex="1" flexDir="column" gap="24px">
            <Flex
              gap="8px"
              flexDir="column"
              fontWeight="700"
              color="pluxee.text.primary"
              fontSize="xs"
            >
              <Text>Dados da transação</Text>

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Valor:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {formatToBrl(Number(transaction.value))}
                </Text>
              </Flex>

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Data:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {transaction.transactionDate
                    ? formatToDateAndTime(transaction?.transactionDate)
                    : '-'}
                </Text>
              </Flex>

              {transaction.description && (
                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Descrição:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {isOutboundTransaction
                      ? formattedWithdrawFeeDesc
                      : `Compra em ${capitalizeSentence(
                          transaction.description
                        )}`}
                  </Text>
                </Flex>
              )}

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Código de resposta:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {transaction.responseCode
                    ? formatResponseCode({ code: transaction.responseCode })
                    : '-'}
                </Text>
              </Flex>

              {transaction.type && (
                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Tipo de transação:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {formatTransactionType({
                      type: transaction.type
                    })}
                  </Text>
                </Flex>
              )}

              {isOutboundTransaction && (
                <>
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Código da ocorrência:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {transaction.occurrenceCode ?? '-'}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Motivo da ocorrência:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {transaction.occurrenceReason ?? '-'}
                    </Text>
                  </Flex>
                </>
              )}

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Modo de entrada:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {transaction.entryMode ? transaction.entryMode : '-'}
                </Text>
              </Flex>

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Trilha:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  <Tag
                    bg="#fff"
                    borderRadius="none"
                    border="1px solid #908C99"
                    p="4px 8px"
                    color="#463F5F"
                    fontWeight="500"
                  >
                    {tagTitle}
                  </Tag>
                </Text>
              </Flex>

              {!isOutboundTransaction && (
                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Cartão:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {formattedCardType} - {formattedCardNumber}
                  </Text>
                </Flex>
              )}

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Código de autorização:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {transaction.authorizationCode ?? '-'}
                </Text>
              </Flex>

              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Número da conta:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {transaction.accountId ?? '-'}
                </Text>
              </Flex>
            </Flex>

            {!isOutboundTransaction && (
              <>
                <Divider />

                <Flex
                  gap="8px"
                  flexDir="column"
                  fontWeight="700"
                  color="pluxee.text.primary"
                  fontSize="xs"
                >
                  <Text>Estabelecimento</Text>

                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Estabelecimento:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {capitalizeSentence(transaction.merchantName ?? '-')}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Código do estabelecimento:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {transaction.establishmentCode ?? '-'}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Código do Adquirente:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {transaction.acquirerCode ?? '-'}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      MCC:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {transaction.mcc
                        ? formatMccDescription(transaction.mcc)
                        : '-'}
                    </Text>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>

        <TransactionHistory transaction={transaction} />
      </VStack>
    </Modal>
  )
}
