import { Flex, Text, Center } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useAlert } from 'hooks/useAlert'
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { goToSection } from 'utils/windowUtils'

import { WalletCard } from './walletCard'

export const ConsumerWallets = () => {
  const { wallets, walletProduct, isLoading, isRefetching, refreshWalletList } =
    useAccountWallet()

  const {
    isSuccessCreditInTrust,
    isErrorCreditInTrust,
    isErrorProvisionalCredit,
    isSuccessReverseTransaction,
    isErrorReverseTransaction,
    isSuccessReverseCredit,
    isErrorReverseCredit
  } = useWalletTransaction()

  const { alert } = useAlert()
  const [isShowSuccessTrustCredit, setIsShowSuccessTrustCredit] =
    useState(false)
  const [isShowSuccessReverseCredit, setIsShowSuccessReverseCredit] =
    useState(false)
  const [isShowSuccessReverseTransaction, setIsShowSuccessReverseTransaction] =
    useState(false)

  useEffect(() => {
    if (isShowSuccessTrustCredit)
      alert({
        id: 'creditInTrustToast',
        title: 'Crédito realizado com sucesso!',
        status: 'success'
      })
  }, [isShowSuccessTrustCredit])

  useEffect(() => {
    if (isShowSuccessReverseCredit)
      alert({
        id: 'reverseCreditToast',
        title: 'Transação estornada com sucesso',
        status: 'success'
      })
  }, [isShowSuccessReverseCredit])

  useEffect(() => {
    if (isShowSuccessReverseTransaction)
      alert({
        id: 'reverseCreditToast',
        title: 'Transação estornada com sucesso',
        status: 'success'
      })
  }, [isShowSuccessReverseTransaction])

  useEffect(() => {
    setIsShowSuccessTrustCredit(isSuccessCreditInTrust)
    if (isSuccessCreditInTrust) {
      refreshWalletList()
      goToSection('consumerWallets-section')
    }
  }, [isSuccessCreditInTrust])

  useEffect(() => {
    if (isErrorCreditInTrust) {
      alert({
        id: 'errorCreditInTrustToast',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'error'
      })
    }
  }, [isErrorCreditInTrust])

  useEffect(() => {
    if (isErrorProvisionalCredit) {
      alert({
        id: 'errorCreditInTrustToast',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'error'
      })
    }
  }, [isErrorProvisionalCredit])

  useEffect(() => {
    setIsShowSuccessReverseTransaction(isSuccessReverseTransaction)
    if (isSuccessReverseTransaction) {
      refreshWalletList()
      goToSection('consumerWallets-section')
    }
  }, [isSuccessReverseTransaction])

  useEffect(() => {
    if (isErrorReverseTransaction) {
      alert({
        id: 'errorReverseCreditToast',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'error'
      })
    }
  }, [isErrorReverseTransaction])

  useEffect(() => {
    setIsShowSuccessReverseCredit(isSuccessReverseCredit)
    if (isSuccessReverseCredit) {
      refreshWalletList()
      goToSection('consumerWallets-section')
    }
  }, [isSuccessReverseCredit])

  useEffect(() => {
    if (isErrorReverseCredit) {
      alert({
        id: 'errorReverseCreditToast',
        title: 'Ocorreu um erro, tente novamente!',
        status: 'error'
      })
    }
  }, [isErrorReverseCredit])

  if (isLoading || isRefetching) return <SkeletonWallet />

  return (
    <Flex flexDir="column" gap="16px" id="consumerWallets-section">
      <Text
        color="pluxee.text.primary"
        fontSize="md"
        fontWeight="700"
        lineHeight="31.2px"
      >
        Carteiras
      </Text>

      {!wallets?.length && (
        <Center w="100%">
          <NoDataFound />
        </Center>
      )}

      <Flex flexWrap="wrap" gap="32px" alignItems="center">
        {wallets?.map(wallet => (
          <WalletCard
            key={`consumerWallet-${wallet.walletId}`}
            wallet={wallet}
            product={walletProduct!}
          />
        ))}
      </Flex>
    </Flex>
  )
}

const SkeletonWallet = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="70" height="10" />
    <rect x="0" y="30" rx="3" ry="3" width="90" height="50" />
    <rect x="105" y="30" rx="3" ry="3" width="90" height="50" />
    <rect x="210" y="30" rx="3" ry="3" width="90" height="50" />
  </ContentLoader>
)
