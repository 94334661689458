import { useOktaAuth } from '@okta/okta-react'
import { CustomerProvider } from 'contexts/customerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomerTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const Customer = () => {
  const navigate = useNavigate()
  const { getStorageItem } = useLocalStorage()
  const { authState, oktaAuth } = useOktaAuth()
  const {
    user,
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isSupervisorB2b,
    isPayments
  } = useProfile()

  useEffect(() => {
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission =
        isAttendant ||
        isFraudAndPrevention ||
        isBackoffice4c ||
        isPayments ||
        isSupervisorB2b

      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isAttendant,
    isFraudAndPrevention,
    isBackoffice4c,
    isSupervisorB2b,
    navigate,
    user,
    authState
  ])

  return (
    <ProfileProvider>
      <CustomerProvider>
        <CustomerTemplate />
      </CustomerProvider>
    </ProfileProvider>
  )
}

export default Customer
