import { Flex, HStack, VStack, Text } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import React from 'react'
import ContentLoader from 'react-content-loader'

import { AccountTransactionsTable } from './table'

export const AccountTransactions = () => {
  const { isLoading, isRefetching, isError } = useWalletTransaction()

  if (isLoading || isRefetching) return <Skeleton />
  if (isError) return <NoDataFound />

  return (
    <>
      <HStack w="100%" justify="space-between" color="#283897">
        <Flex gap="8px" alignItems="center" justifyContent="center">
          <Text
            color="pluxee.text.primary"
            fontSize="md"
            fontWeight="700"
            lineHeight="31.2px"
          >
            Transações da conta
          </Text>
        </Flex>
      </HStack>

      <VStack align="start" w="100%">
        <HStack w="100%">
          <AccountTransactionsTable />
        </HStack>
      </VStack>
    </>
  )
}

export const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)
