import { HStack, VStack } from '@chakra-ui/react'
import { Divider, Flex, Text } from 'components/atoms'
import { Loading } from 'components/ui'
import { CloseButton, Modal, StatusLabel } from 'components/ui'
import { useAccountEvents } from 'contexts/accountEventsContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { formatCNPJ, formatCPF, translate } from 'internationalization'
import { Account } from 'models/Account'
import React from 'react'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'

import { HistoryTable } from './historyTable'

type HistoryModalProps = {
  isOpen: boolean
  onClose: () => void
  account: Account
}

export const HistoryModal = ({
  isOpen,
  onClose,
  account
}: HistoryModalProps) => {
  const { removeStorageItem } = useLocalStorage()
  const { getEventsByFilter } = useAccountEvents()
  const { isLoading, isLoadingByFilters } = useAccountEvents()

  const showLoading = isLoading || isLoadingByFilters
  const modalFooter = <CloseButton onClick={onClose} />

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
      decreaseMarginTop
      footer={modalFooter}
      heading={translate('commons.accountHistory')}
      onCloseComplete={() => {
        removeStorageItem('historyFilterDate')
        getEventsByFilter({
          dateStart: '',
          dateEnd: ''
        })
      }}
    >
      <VStack spacing="xxs" align="start">
        <HStack justify="space-between" w="100%">
          <Text fontWeight="700" color="pluxee.text.link" fontSize="md">
            Conta #{account.accountId}
          </Text>

          <Flex flexDir="column" gap="6px" alignItems="flex-end">
            <Flex gap="8px" alignItems="center">
              <Text fontWeight="700" fontSize="xs" color="pluxee.text.primary">
                Status:
              </Text>
              <StatusLabel statusType={account.status} />
            </Flex>
          </Flex>
        </HStack>

        <Divider />

        <Flex w="100%" gap="24px">
          <Flex flex="1" flexDir="column" gap="24px">
            <Flex
              gap="8px"
              flexDir="column"
              fontWeight="700"
              color="pluxee.text.primary"
              fontSize="xs"
            >
              <Text>Dados da conta</Text>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Colaborador:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {account.consumer?.name ?? '-'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  CPF:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {formatCPF(account.consumer?.document) ?? '-'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Produto:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {account.productId ?? '-'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Criado em:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {formatDateToBrazilianFormat(account.creationDate)}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Inativado em:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {account.closureDate
                    ? formatDateToBrazilianFormat(account.closureDate)
                    : '-'}
                </Text>
              </Flex>
            </Flex>

            <Divider />

            <Flex
              gap="8px"
              flexDir="column"
              fontWeight="700"
              color="pluxee.text.primary"
              fontSize="xs"
            >
              <Text>Dados do cliente</Text>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Cliente:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {account.customer?.name ?? '-'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  Cód. do cliente:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {account.customer?.id ?? '-'}
                </Text>
              </Flex>
              <Flex>
                <Text minW="280px" fontSize="xxs">
                  CNPJ:
                </Text>
                <Text fontWeight="500" fontSize="xxs">
                  {formatCNPJ(account.customer?.document) ?? '-'}
                </Text>
              </Flex>
            </Flex>

            <Divider />

            {!showLoading && <HistoryTable />}
            {showLoading && <Loading />}
          </Flex>
        </Flex>
      </VStack>
    </Modal>
  )
}
