import { Tag, useDisclosure } from '@chakra-ui/react'
import { Text } from 'components/atoms'
import { Table, TransactionStatus } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useProfile } from 'contexts/profileContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { usePagination } from 'hooks/usePagination'
import { translate } from 'internationalization'
import { type WalletTransaction } from 'models/WalletTransaction'
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

import { TransactionDetailsModal } from '../../modals/transactionDetails'
import { ProvisionalCredit } from '../../operations/provisionalCredit'
import { ReverseTransaction } from '../../operations/reverseTransaction'
import { TransactionOptions } from '../../options/transactionOptions'

export const TransactionsTable = () => {
  const {
    user,
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    isBackoffice4c,
    isFraudAndPrevention,
    isManageTransactions,
    isOperation3c,
    isPayments
  } = useProfile()

  const is3cProfile = user?.role?.product_id === 34

  const {
    transactions,
    isLoading,
    isLoadingWithFilters,
    walletTransactionsPagination,
    getTransactiosByFilter
  } = useWalletTransaction()

  const { currentPage, handlePrevPage, handleNextPage, handleOnChangePage } =
    usePagination({
      initialPage: walletTransactionsPagination?.currentPage ?? 0
    })

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  const {
    isOpen: isOpenProvisionalCredit,
    onOpen: onOpenProvisionalCredit,
    onClose: onCloseProvisionalCredit
  } = useDisclosure()

  const {
    isOpen: isOpenReverseTransaction,
    onOpen: onOpenReverseTransaction,
    onClose: onCloseReverseTransaction
  } = useDisclosure()

  const [selectedTransaction, setSelectedTransaction] =
    useState<WalletTransaction | null>(null)

  const columns: CellProps[] = [
    {
      title: 'Data e hora',
      width: '140px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="150%"
            color={isUndoneTransaction ? '#969698' : '#463F5F'}
          >
            {formatToDateAndTime(data.transactionDate, true)}
          </Text>
        )
      }
    },
    {
      title: 'Descrição',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'
        const capitalizedDescription = capitalizeSentence(data.description)

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isUndoneTransaction ? '#969698' : '#221C46'}
          >
            Compra em {limitString(capitalizedDescription, 50)}
          </Text>
        )
      }
    },
    {
      title: translate('commons.value'),
      width: '100px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontWeight="700"
            fontSize="14px"
            textDecor={isUndoneTransaction ? 'line-through' : 'initial'}
            color={isUndoneTransaction ? '#969698' : '#0F2365'}
          >
            {formatToBrl(Number(data.value))}
          </Text>
        )
      }
    },
    {
      title: 'Trilha',
      hidden: is3cProfile,
      width: '100px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        return (
          <Tag
            bg="#fff"
            borderRadius="none"
            border="1px solid #908C99"
            p="4px 8px"
            color="#463F5F"
            fontWeight="500"
          >
            {data.voucherTransaction ? 'Voucher' : 'Crédito'}
          </Tag>
        )
      }
    },
    {
      title: translate('commons.status'),
      width: '96px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        return (
          <TransactionStatus
            status={data.status}
            responseCode={data.responseCode}
          />
        )
      }
    },
    {
      title: 'Tipo',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isUndoneTransaction ? '#969698' : '#221C46'}
          >
            {TransactionUtils.formatTransactionType({ type: data.type })}
          </Text>
        )
      }
    },
    {
      title: '',
      width: '5%',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isShowProvisionalCredit =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E'

        const isShowReverseTransaction =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isFraudAndPrevention &&
          !isOperation3c &&
          !isManageTransactions &&
          !isPayments &&
          data.status === 'E'

        return (
          <TransactionOptions
            data={data}
            isShowProvisionalCredit={isShowProvisionalCredit}
            isShowReverseTransaction={isShowReverseTransaction}
            onOpenProvisionalCredit={onOpenProvisionalCredit}
            onOpenDetails={onOpenDetails}
            onOpenReverseTransaction={onOpenReverseTransaction}
            setSelectedTransaction={setSelectedTransaction}
          />
        )
      }
    }
  ]

  useEffect(() => {
    handleOnChangePage(0)
  }, [])

  let minimumConsumersForPagination = 20
  if (walletTransactionsPagination?.lastPage) minimumConsumersForPagination = 1

  const isPaginated =
    transactions.length >= minimumConsumersForPagination &&
    walletTransactionsPagination!.totalPages > 1

  useEffect(() => {
    getTransactiosByFilter({ type: 'PTX', page: currentPage })
  }, [currentPage])

  if (isLoading || isLoadingWithFilters) return <Skeleton />

  return (
    <>
      <Table
        textType="initial"
        minWidth="100%"
        size="sm"
        columns={columns}
        list={transactions}
        isPaginated={isPaginated}
        currentPage={currentPage}
        totalPages={walletTransactionsPagination?.totalPages}
        totalResults={walletTransactionsPagination?.totalResults}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleOnChangePage={pageNumber => handleOnChangePage(pageNumber)}
        onClickRow={data => {
          setSelectedTransaction(data)
          onOpenDetails()
        }}
      />

      <TransactionDetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <ProvisionalCredit
        isOpen={isOpenProvisionalCredit}
        onClose={onCloseProvisionalCredit}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <ReverseTransaction
        isOpen={isOpenReverseTransaction}
        onClose={onCloseReverseTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />
    </>
  )
}

const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)
